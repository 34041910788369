.popup
  padding: var(--g-section-inner-32) var(--g-section-inner-16)
  @media(max-width: 768px)
    padding: var(--g-section-inner-16)
    place-items: start
    height: -webkit-fill-available
    height: calc(100 * var(--vh, 1vh))
  &--content
    width: 100%
    max-width: 780px
    background-color: #121112
    border-radius: var(--border-corner-card)
    border: 1px solid var(--c-secondary)
    padding: var(--g-section-inner-32)
  &--close
    display: flex
    align-items: center
    justify-content: center
    position: absolute
    top: 8px
    right: 8px
    z-index: 5
    cursor: pointer
    transition: 0.3s ease-out
    font-family: 'icomoon' !important
    speak: never
    font-style: normal
    font-weight: normal
    font-variant: normal
    text-transform: none
    line-height: 1
    -webkit-font-smoothing: antialiased
    -moz-osx-font-smoothing: grayscale
    width: 42px
    height: 42px
    border-radius: var(--border-corner)
    font-size: 25px
    &:after
      content: "\e94a"
      position: absolute
      top: 50%
      left: 50%
      transform: translate(-50%, -50%)
    @media(max-width: 768px)
      left: unset
      right: 16px
      top: 16px
.popup.popup--menu
  place-items: end
  padding: 0
  .popup--close
    width: 32px
    height: 32px
  .popup--content
    overflow: auto
    width: clamp(325px, 25vw, 360px)
    opacity: 0
    transition: 0.18s ease-out
    height: calc(100vh - var(--headerHeight))
    border-radius: 0
    @media(max-width: 768px)
      width: 100%
  .nav
    display: flex
    max-width: 312px
    margin: auto
    flex-direction: column
    align-items: flex-start
    &--item
      overflow: hidden
      flex-direction: column
      &_popover
        max-height: 0
        transition: max-height 0.3s ease
      &_button
        padding: 0
      &.is_open
        .nav--item_popover
          animation: slidein 2s forwards
        & [class^=icon-]
          transform: scale(1, -1)
    &--list
      padding: 0
      &_item
        padding: 8px
  &.is_active
    .popup--content
       opacity: 1

.popup.popup--form,
.popup.popup-cascade
  background-color: rgba(0,0,0,0.35)
  backdrop-filter: blur(5px)

.popup[data-popup="popup_for_notification"]
  justify-items: center
  align-items: end
  .popup--content
    max-width: 512px
    padding: 12px
    background-color: #2A292A
    display: flex
    gap: 16px
    justify-content: space-between
    align-items: center
  .popup--content-title
    font-size: 16px
    font-weight: 400

.popup[data-popup="popup_contents"]
  .popup--content
    width: 100vw
