.page--documentation
  --gap-documentation: 56px
  @media(min-width: 999px)
    --gap-documentation: clamp(24px, calc(24px + (80 - 24) * ((100vw - 1240px) / (1920 - 1240))), 80px)
    .page
      display: grid
      grid-template-columns: 300px auto
      gap: var(--gap-documentation)
      @media(min-width: 1240px)
        grid-template-columns: 320px auto
    main
      padding: 0
.content_documentation
  padding-top: var(--g-section-inner-32)
  .block--wrapper
    display: flex
    flex-direction: column-reverse
    gap: var(--gap-documentation)

    @media(min-width: 999px)
      padding: 0
      display: grid
      grid-template-columns: auto 300px
      @media(min-width: 1240px)
        grid-template-columns: auto 320px
    article
      @media(min-width: 999px)
        margin: auto
        max-width: 32vw
        width: 100%
.sidebar
  height: 100%
  border-bottom: 1px solid var(--c-secondary)
  margin: 0 calc(var(--container-padding) * -1)
  padding: 0 var(--container-padding) var(--g-section-inner-48)
  @media(min-width: 999px)
    padding: 0 var(--g-section-inner-24)
    border: none
    margin: 0
  &--wrapper
    position: sticky
    top: var(--g-section-inner-32)
  &--title
    display: flex
    align-items: center
    gap: 6px
    font-size: var(--fs-body-sx)
    margin-bottom: 10px
    [class^=icon-]
      font-size: 1.2em
  &--content
    font-size: 15px
  @media(max-width: 998px)
    .sidebar--content
      position: fixed
      opacity: 0
      pointer-events: none
.sidebar,
.popup[data-popup="popup_contents"]
  .list
    list-style-type: none
  .list__item
    padding:  4px
    color: var(--c-text-secondary)
    a
      padding: 4px
      text-overflow: ellipsis
      overflow: hidden
      display: -webkit-box
      -webkit-line-clamp: 2
      -webkit-box-orient: vertical
      &:hover
        color: #fff
    &.is_active
      color: #fff
      border-color: #fff
  .list .list
    .list__item
      border-left: 1px solid var(--c-text-secondary)
      margin-left: 6px
