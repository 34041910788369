.form--input_date
  font-size: var(--fs-md)
  border-radius: var(--c-input-bg)
  border: 1px solid transparent
  background-color: var(--neutral-container-soft)
  transition: var(--transition)
  width: 100%
  position: relative
  &:after
    content: "\e909"
    font-family: 'icomoon'
    margin-left: auto
    font-size: var(--fs-md)
    position: absolute
    top: 12px
    right: 16px
    color: var(--c-text-disabled)
  input
    padding: 12px 16px
    line-height: 1.5
    font-size: 16px
    border-radius: 8px
    border: 1px solid var(--c-input-border)
    background-color: var(--c-input-bg)
    transition: var(--transition)
    width: 100%
    &::placeholder,
    &:placeholder-shown
      color: var(--c-text-disabled)
    &:not(:placeholder-shown)
      color: var(--c-text-default)
    &:hover,
    &:focus,
    &:focus-within
      border-color: var(--c-input-border-hover)


.air-datepicker
  color: var(--c-text-default)
  --adp-font-family: inherit
  --adp-font-size: 16px
  --adp-width: 328px
  --adp-z-index: 100
  --adp-padding: 0
  --adp-grid-areas: 'nav' 'body' 'timepicker' 'buttons'
  --adp-transition-duration: .3s
  --adp-transition-ease: ease-out
  --adp-transition-offset: 8px
  --adp-background-color: #1E1D1E
  --adp-background-color-hover: transparent
  --adp-background-color-active: transparent
  --adp-background-color-in-range: var(--neutral-container-soft)
  --adp-background-color-in-range-focused: var(--neutral-container-soft)
  --adp-background-color-selected-other-month-focused: var(--c-text-default)
  --adp-background-color-selected-other-month: var(--c-text-default)
  --adp-color: #4a4a4a
  --adp-color-secondary: #9c9c9c
  --adp-accent-color: var(--c-text-default)
  --adp-color-current-date: var(--accent-default)
  --adp-color-other-month: var(--c-text-default)
  --adp-color-disabled: var(--fg-disabled)
  --adp-color-disabled-in-range: #939393
  --adp-color-other-month-hover: #c5c5c5
  --adp-border-color: transparent
  --adp-border-color-inner: transparent
  --adp-border-radius: 4px
  --adp-border-color-inline: var(--c-secondary)
  --adp-nav-height: 32px
  --adp-nav-arrow-color: var(--c-text-default)
  --adp-nav-action-size: 32px
  --adp-nav-color-secondary: var(--c-text-default)
  --adp-day-name-color: var(--c-text-disabled)
  --adp-day-name-color-hover: var(--c-text-default)
  --adp-day-cell-width: 1fr
  --adp-day-cell-height: 36px
  --adp-month-cell-height: 42px
  --adp-year-cell-height: 56px
  --adp-pointer-size: 10px
  --adp-poiner-border-radius: 2px
  --adp-pointer-offset: 14px
  --adp-cell-border-radius: 8px
  --adp-cell-background-color-hover: var(--adp-background-color-hover)
  --adp-cell-background-color-selected: var(--c-secondary)
  --adp-cell-background-color-selected-hover: var(--c-secondary-hover)
  --adp-cell-border-color-in-range: var(--c-text-default)
  --adp-btn-height: 48px
  --adp-time-track-height: 1px
  --adp-time-track-color: #dedede
  --adp-time-track-color-hover: #b1b1b1
  --adp-time-thumb-size: 12px
  --adp-time-padding-inner: 10px
  --adp-time-day-period-color: var(--adp-color-secondary)
  padding: 16px
  border-radius: 16px
  -webkit-box-shadow: 0 4px 6px -2px rgba(26, 26, 26, 0.2), 0 1px 0 0 rgba(204, 204, 204, 0.5) inset, 0 -1px 0 0 rgba(0, 0, 0, 0.17) inset, -1px 0 0 0 rgba(0, 0, 0, 0.13) inset, 1px 0 0 0 rgba(0, 0, 0, 0.13) inset
  box-shadow: 0 4px 6px -2px rgba(26, 26, 26, 0.2), 0 1px 0 0 rgba(204, 204, 204, 0.5) inset, 0 -1px 0 0 rgba(0, 0, 0, 0.17) inset, -1px 0 0 0 rgba(0, 0, 0, 0.13) inset, 1px 0 0 0 rgba(0, 0, 0, 0.13) inset

@media (max-width: 992px)
  .air-datepicker
    padding: 8px

.air-datepicker-cell:not(.-disabled-):hover
  background:  var(--c-secondary-hover)
.air-datepicker-button.airpicker_button_apply
  min-width: 100%
  color: var(--filled-c-base, #fff)
  border-radius: var(--border-corner)
  background: var(--filled-bgc-base, #100a0a)


.air-datepicker--buttons
  padding-top: 16px

.air-datepicker-body--day-names
  padding: 8px 0

.air-datepicker-body--day-name
  font-size: 14px

.-disabled-.-focus-.air-datepicker-cell,
.air-datepicker-cell.-day-.-other-month-,
.air-datepicker-cell.-year-.-other-decade-
    color: var(--c-text-disabled)

.air-datepicker-cell.-disabled-
    color: var(--c-text-disabled) !important

.air-datepicker-cell.-in-range-
  background: var(--c-secondary)
