.menu
  overflow: scroll
  padding: 0 32px 12px
  height: 100%
  //margin-top: 72px
  &--nav
    display: grid
    grid-template-columns: repeat(2, 1fr)
    gap: 32px 8px
    &--item
      &_list
        display: flex
        flex-direction: column
        gap: 8px
      .button
        padding: 0
        font-size: 16px
      &-solutions
        grid-column: span 2
        display: flex
        flex-direction: column
        gap: 16px
        color: #FFFFFF
        &__image
          aspect-ratio: 1/1
          max-width: 25%
          display: flex
          border-radius: 6px
          overflow: hidden
        a
          gap: 16px
          padding: 4px
          display: flex
          align-items: center
          background-color: #121111
          border-radius: 6px
          position: relative
          p
            font-weight: 700
          span
            font-size: 14px
          &:before
            font-family: 'icomoon' !important
            content: "\e91b"
            width: 16px
            height: 16px
            position: absolute
            top: 9px
            left: auto
            right: 8px
            color: #FFFFFF
      &_title
        color: #8C8989
        font-size: 14px
        margin-bottom: 12px

