.content_404
  .block--wrapper
    padding: var(--g-section-inner) var(--container-padding)
    display: flex
    flex-direction: column
    align-items: center
  .block--head
    align-items: center
  h1
    font-size: clamp(32px, calc(32px + (96 - 32) * ((100vw - 1024px) / (1920 - 1024))), 96px)
    text-align: center
