.content_contacts
  .block--elements
    display: grid
    grid-template-columns: 1fr
    gap: 16px
    margin-top: var(--g-section-inner)
    @media(min-width: 769px)
      grid-template-columns: repeat(2, 1fr)
      gap: var(--g-section-inner-32)
  .card
    border: 1px solid var(--c-secondary)
    background: #1E1D1E
    padding: var(--g-section-inner-32)
    height: min-content
    &--content
      display: flex
      flex-direction: column
      height: 100%
      padding: 0
    &--title
      display: flex
      gap: 12px
      margin-bottom: 16px
      [class^=icon-]
        line-height: 1.3
    &--text
      line-height: 1.4
      -webkit-line-clamp: inherit
    &--action
      display: flex
      gap: 8px
      margin-top: auto
      .button
        margin-top: 16px
  .card:only-child
    text-align: center
    @media(min-width: 769px)
      max-width: 50%
      margin: auto
      grid-column: span 2
    .card--title,
    .card--action
      justify-content: center

