/* Video widget

.video_widget
  position: fixed
  bottom: var(--g-content, 40px)
  left: var(--g-content, 40px)
  justify-self: start
  border: 3px solid #fff
  transition: 0.18s ease-in-out
  transform: scale(0.8)
  transform-origin: bottom left
  max-width: 164px
  aspect-ratio: 124 / 189
  height: auto
  overflow: hidden
  cursor: pointer
  z-index: 100
  border-radius: 16px
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.04), 0px 8px 16px 0px rgba(0, 0, 0, 0.08)
  background: rgba(0, 0, 0, 0.3)

  &:not(.is_active):hover
    border-color: var(--c-surface-accent)
    transform: scale(1)

  &.is_active
    max-width: 312px
    width: 312px
    border-color: var(--c-surface-accent)
    transform: scale(1)

@media (max-width: 768px)
  .video_widget
    bottom: 12px
    left: 12px

.video-controls--volume, .video_widget--close
  border-radius: 50%
  width: 42px
  height: 42px
  background-color: rgba(0, 0, 0, 0.4)
  color: #fff
  z-index: 3
  display: flex
  align-items: center
  justify-content: center
  font-size: 18px
  position: absolute
  transform-origin: center
  transform: scale(1)
  transition: var(--transition)

.video-controls--volume
  &:hover, &:active
    transform: scale(1.1)

.video_widget--close
  &:active, &:hover
    transform: scale(1.1)

  top: 6px
  right: 4px
  opacity: 0

@media (max-width: 768px)
  .video_widget--close
    opacity: 1

.video_widget
  &:not(.is_active):hover .video_widget--close, &.is_active .video_widget--close
    opacity: 1

.video-controls--volume
  top: 52px
  right: 4px
  opacity: 0

  span
    position: absolute
    top: 50%
    left: 50%
    transform: translate(-50%, -50%)
    transition: var(--transition)

  .icon-ic-volume
    opacity: 0
  &.is_not_muted
    .icon-ic-volume
      opacity: 1

    .icon-ic-volume-x
      opacity: 0

.video-controls video
  width: 100%
  height: 100%
  object-fit: cover
  object-position: center

video[poster]
  object-fit: cover

.video-controls
  display: flex
  align-items: flex-start
  justify-content: flex-start
  position: relative
  cursor: pointer
  width: 100%
  height: 100%
  pointer-events: none

.video_widget.is_active
  .video-controls
    pointer-events: all

  .video-controls--volume, .video-controls--progress
    opacity: 1

.video-controls--progress
  position: absolute
  top: 0
  left: 0
  right: 0
  width: 100%
  height: 4px
  --progress-bg:rgba(255, 255, 255, 0.6)
  --progress-line:var(--c-surface-accent)
  background-color: var(--progress-bg)
  transition: 0.18s ease-in
  opacity: 0

  span
    position: absolute
    top: 0
    left: 0
    height: 100%
    display: block
    width: 0
    background-color: var(--progress-line)

@media (max-width: 768px)
  .video-controls--progress
    opacity: 1

.video-controls--play
  position: absolute
  top: calc(50% - 32px)
  left: calc(50% - 32px)
  transition: var(--transition)
  border-radius: 50%
  width: 64px
  height: 64px
  background-color: rgba(0, 0, 0, 0.2)
  color: #fff
  opacity: 0
  transform-origin: center
  pointer-events: none

.video_widget.is_active .video-controls--play.is_paused
  opacity: 1

  &.is_ended
    opacity: 1 !important

.video-controls--play
  .icon-ic-play, .icon-ic-pause
    position: absolute
    top: 50%
    left: 50%
    transform: translate(-50%, -50%)
    font-size: 24px
    width: 24px
    height: 24px
    display: flex
    align-items: center
    justify-content: center
    transition: var(--transition)

  &.is_playing
    .icon-ic-play
      opacity: 0

    .icon-ic-pause
      opacity: 1

  &.is_paused
    .icon-ic-play
      opacity: 1

    .icon-ic-pause
      opacity: 0

@keyframes pauseVideo
  0%
    opacity: 0
    scale: 1

  30%
    opacity: 100%
    scale: 2

  100%
    opacity: 0
    scale: 1

.video-controls--button
  position: absolute
  bottom: 12px
  left: 50%
  transform: translateX(-50%)
  z-index: 5
  opacity: 0
  transition: var(--transition)

  span
    max-width: 220px
    text-overflow: ellipsis
    overflow: hidden

.video_widget.is_active .video-controls--button.is_shown
  opacity: 1

.video-controls--timer
  font-size: 12px
  color: #fff
  width: 24px
  position: absolute
  top: 12px
  left: 12px
  z-index: 2
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5)
  opacity: 0
  transition: var(--transition)

.video_widget.is_active .video-controls--timer
  opacity: 1
