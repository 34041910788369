.block
  padding: var(--g-vertical) 0
  //&:not(.content_documentation):first-of-type
  //  padding-top: var(--headerHeight)
  &--wrapper
    padding: 0 var(--container-padding)
    margin: auto
    display: flex
    flex-direction: column

.block_list .block--wrapper
  @media (min-width: 1024px)
    flex-wrap: wrap
    flex-direction: row
    justify-content: space-between
    .block--head
      grid-area: heading
      width: 80%
    .block--selection
      width: auto
      &:has(.block--actions)
        width: 100%
    .block--sections
      width: 100%
    .block--elements
      width: 100%

  &--elements
    margin-top: var(--g-section-inner)

.block.-centered
  .block--wrapper
    //flex-direction: column
    align-items: center
  .block--head
    text-align: center
    align-items: center
    max-width: 544px
    margin: 0 auto
  .block--wrapper
    justify-content: center
  .block--selection
    width: 100%
    justify-content: center


