.form--input_file
  border: 1px solid var(--c-input-border)
  background-color: var(--c-input-bg)
  padding: 12px 16px
  border-radius: 8px
  cursor: pointer
  width: 100%
  display: flex
  justify-content: flex-start
  align-items: center
  position: relative
  gap: 12px
  color: var(--c-text-disabled)
  &:hover,
  &:focus,
  &:focus-within
    border-color: var(--c-input-border-hover)
  .icon-ic-paperclip
    line-height: 1.5
  p
    font-size: 16px
    font-weight: 400
    line-height: 1.5
    margin: 0
  input
    position: absolute
    top: 0
    left: 0
    z-index: 2
    opacity: 0
    width: 100%
    height: 100%
    cursor: pointer

.form--input_file--items
  display: flex
  flex-direction: column
  gap: 8px
  margin: 12px 0
  &:empty
    display: none

.form--input_file--items_loaded
  padding: 12px 44px 12px 60px
  border: 1px solid var(--c-input-border)
  //background-color: var(--c-input-bg)
  border-radius: 8px
  position: relative
  font-size: 16px
  &:before
    content: "\e927"
    font-family: 'icomoon' !important
    position: absolute
    left: 0
    top: 0
    width: 60px
    height: 100%
    animation: loading 1.6s infinite linear
    display: flex
    align-items: center
    justify-content: center
  & > p, span
    margin: 0
  &.loaded
    &:before
      animation: none
      content: "\e918"
  &.loaded.error
    &:before
      content: "\e91a"
      color: var(--error-color)



@keyframes loading
  0%
    transform: rotate(0deg)
  100%
    transform: rotate(360deg)

.form--input_file--items_delete
  content: ""
  width: 42px
  height: 42px
  position: absolute
  right: 0
  top: 50%
  transform: translateY(-50%)
  display: grid
  place-items: center
  cursor: pointer
  color: var(--c-text-disabled)
  transition: var(--transition)
  &:hover
    color: var(--c-text-default)

.form--input_file--items_error
  padding-top: 8px
  font-size: 12px
  color: var(--error-color)
