input, textarea, select
  border: none
  outline: none
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button
  -webkit-appearance: none
  margin: 0

/* Firefox */
input[type='number']
  -moz-appearance: textfield

textarea
  resize: vertical


form
  --c-input-bg: var(--c-base-bgc)
  --c-input-border: rgba(255,255,255, 0.10)
  --input-check-rest: rgba(255,255,255, 0.35)
  --c-input-border-hover: rgba(255,255,255, 0.4)
  --input-check-hover: var(--c-white)
  --input-check-color: var(--c-black)
  --error-color: --c-critical
  max-width: 868px
  fieldset
    padding: 0
    border-color: transparent
    display: flex
    flex-direction: column
    gap: var(--g-section-inner-32)
  .form--title
    font-size: var(--fs-heading-md)
    font-weight: 500
    letter-spacing: -0.02em
    margin-bottom: var(--g-section-inner-16)
  .form--text
    margin-bottom: var(--g-section-inner-16)
    font-size: var(--fs-body-sx)
  legend
    color: var(--c-text-default)
    font-size: var(--fs-heading-lg)
    font-weight: 700
    line-height: 1.14
    letter-spacing: -0.02em
    font-family: 'Unbounded', sans-serif
    margin-bottom: var(--g-section-inner-32)
  .form--input
    display: flex
    flex-direction: column
    & > span
      order: -1
      font-size: var(--fs-body-md)
      font-weight: 500
      transition: var(--transition)
      margin-bottom: 12px
      color: var(--c-text-default)
    & > input, textarea
      padding: 12px 16px
      line-height: 1.5
      font-size: 16px
      border-radius: var(--border-corner)
      border: 1px solid var(--c-input-border)
      background-color: var(--c-base-bgc)
      transition: var(--transition)
      width: 100%
      &::placeholder,
      &:placeholder-shown
        color: var(--c-text-disabled)
      &:not(:placeholder-shown)
        color: var(--c-text-default)
      &:hover,
      &:focus,
      &:focus-within
        border-color: var(--c-input-border-hover)
      &:invalid:not(:placeholder-shown)
        border-color: var(--c-critical)
      &:valid:not(:placeholder-shown)
        border-color: var(--c-input-border-hover)
        &:not(:focus)
          border-color: var(--c-input-border)
      &:required ~ span:after
        content: '*'
        color: var(--c-accent)
        margin-left: 5px
    &.error
      .form--input_error
        opacity: 1
        max-height: fit-content
        height: auto
        color: var(--error-color)
        padding-top: 12px
    &_error
      color: var(--c-critical)
      opacity: 0
      font-size: 16px
      line-height: 24px
      max-height: 0
      height: 0
      overflow: hidden
      transition: opacity 0.03s 0.15s ease-out, max-height .18s linear

    & > label
      display: flex
      align-items: flex-start
      & + label
        margin-top: 16px
  input[type="radio"],
  input[type="checkbox"]
    display: none
    order: -1
    & ~ span
      order: 1
      font-size: clamp(16px, calc(16px + (18 - 16) * ((100vw - 768px) / (1920 - 768))), 18px)
      line-height: 24px
      margin-left: 12px
      color: var(--c-text-default)
    & ~ .radio,
    & ~ .checkbox,
    & ~ .slider
      flex-shrink: 0
      flex-grow: 0
      cursor: pointer
  input[type="checkbox"]
    & ~ .checkbox
      transition: var(--transition)
      width: 24px
      height: 24px
      border-radius: 6px
      border: 2px solid var(--input-check-rest)
      background-color: transparent
      position: relative
      display: flex
      align-items: center
      justify-content: center
      &:after
        content: '\e90a'
        font-family: 'icomoon' !important
        color: var(--input-check-color)
        transition: var(--transition)
        opacity: 0
        font-size: 16px
      &:hover,
      &:focus
        border-color: var(--input-check-hover)
        background-color: transparent
    & ~ .slider
      width: 46px
      height: 22px
      outline: 1px solid var(--input-check-rest)
      border-radius: 1000px
      position: relative
      transition: var(--transition)
      background-color: rgba(255,255,255, 0.4)
      &:after
        content: ""
        width: 20px
        height: 20px
        position: absolute
        left: 2px
        top: 1px
        border-radius: 50%
        background-color: var(--c-black)
        transition: var(--transition)
        z-index: 2
        flex-shrink: 0
        flex-grow: 0

    &:checked
      & ~ .checkbox
        background-color: var(--input-check-hover)
        border-color: var(--input-check-hover)
        &:after
          opacity: 1
      & ~ .slider
        background-color: var(--c-accent)
        outline-color: var(--c-accent)
        &:after
          transform: translateX(22px)
          background-color: var(--c-black)

  input[type="radio"]
    & ~ .radio
      transition: var(--transition)
      width: 24px
      height: 24px
      border-radius: 50%
      border: 2px solid var(--input-check-rest)
      background-color: transparent
      display: flex
      align-items: center
      justify-content: center
      position: relative
      &:after
        content: '\e90a'
        font-family: 'icomoon' !important
        color: var(--input-check-color)
        opacity: 0
        transition: var(--transition)
        font-size: 16px
      &:hover,
      &:focus
        border-color: var(--input-check-hover)
        background-color: transparent
    &:checked
      & ~ .radio
        background-color: var(--input-check-hover)
        border-color: var(--input-check-hover)
        &:after
          opacity: 1


  input[type="date"]
    appearance: none

.form--policy
  font-size: 16px
  font-weight: 400
  color: var(--c-text-disabled)
  a:not(.button)
    font-size: 16px
    font-weight: 400
    color: var(--c-text-default)

.form.is_sending
  button[type="submit"]
    background: var(--c-secondary-hover)
    pointer-events: none


