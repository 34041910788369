.block--sections
  padding: var(--g-section-inner) 0 0
  margin-top: var(--g-section-inner)
  border-top: 1px solid var(--c-secondary)
  @media(max-width: 768px)
    max-width: 100vw
    overflow-x: auto
    padding: var(--g-section-inner) var(--container-padding)
    margin: var(--g-section-inner) calc( var(--container-padding) * -1) 0
  a
    border-radius: 100px
    text-align: center
    white-space: nowrap
    padding: 6px 12px
    font-size: var(--fs-body-sm)
    color: #FFFFFF
    opacity: 0.64
    background-color: transparent
    z-index: 3
    transition: var(--transition)
    &:hover,
    &:focus,
    &:active
      opacity: 1
    &.is_active
      color: var(--c-text-default)
      opacity: 1

.block--sections_selector
  position: absolute
  top: 2px
  left: 0
  background-color: #CAFA65
  border-radius: 100px
  height: calc(100% - 4px)
  z-index: 2
  transition: var(--transition)
