.block_banner
  overflow: hidden
  position: relative
  display: flex
  .block--wrapper
    height: 100%
    grid-column: content
    position: relative
    width: 100%
  .block--head,
  .block--selection
    z-index: 2
    position: relative
  .block--content
    width: 100vw
    margin: var(--g-section-inner-48) calc(var(--container-padding) * -1) 0
    overflow: hidden
    order: 1
    img, video
      width: 100%
      height: 100%
      object-position: center
      object-fit: cover
      aspect-ratio: 1140/630
      //max-height: 630px
  .block--head
    & > *
      z-index: 2
      position: relative
    .block--head_caption
      max-width: 600px


.block_banner-group
  .banner
    background-color: var(--c-base-bgc)
  .slider_controls
    display: flex
    gap: 16px
    order: 1
    width: 100%
    align-items: center
    color: #fff
    z-index: 10
    justify-content: center
    pointer-events: none

.block_banner.-centered
  .block--actions
    justify-content: center
