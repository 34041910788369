
.swiper-slide,
.card,
.gallery--item,
.block_banner-group .banner
  -webkit-transform: translate3d(0, 0, 0)
  -webkit-backface-visibility: hidden
  flex-shrink: 0
  flex-grow: 0

.swiper-wrapper
  -webkit-transform-style: preserve-3d

.swiper-prev,
.swiper-next
  border-radius: 100px
  position: relative
  line-height: 1
  cursor: pointer
  display: flex
  align-items: center
  justify-content: center
  font-size: var(--fs-heading-md)
  flex-shrink: 0
  flex-grow: 0
  pointer-events: all
  &:after
    font-family: 'icomoon' !important
    color: #fff
    z-index: 2
    position: relative



.swiper-button-next,
.swiper-button-prev,
.swiper-prev,
.swiper-next
  border-radius: var(--border-corner)
  background-color: var(--c-secondary)
  pointer-events: auto
  line-height: 1
  cursor: pointer
  display: flex
  align-items: center
  justify-content: center
  flex-shrink: 0
  flex-grow: 0
  width: 40px
  height: 40px
  position: static
  @media(min-width: 769px)
    width: 44px
    height: 44px
  &:after
    font-family: 'icomoon' !important
    color: var(--c-text-default)
    font-size: 16px
    z-index: 2

.swiper-next,
.swiper-button-next
  &:after
    content: "\e942"

.swiper-next
  &:before
    right: -12px


.swiper-prev,
.swiper-button-prev
  &:after
    content: "\e941"

.swiper-prev
  &:before
    left: -12px


.swiper-button-disabled
  cursor: auto
  opacity: 0.3

@media(max-width: 769px)
  .slider_controls
    display: none
