.accordion
  cursor: pointer
  position: relative
  overflow: hidden
  transition: var(--transition)
  border-bottom: 1px solid var(--c-secondary)
  @media(max-width: 768px)
    padding: 4px
  &.is_open
    .icon
      color: #fff
      transform: rotate(90deg)
      transform-origin: center
  &--button
    cursor: pointer
    display: flex
    justify-content: space-between
    align-items: flex-start
    position: relative
    padding: var(--g-section-inner-24) var(--g-section-inner-24) var(--g-section-inner-24) var(--g-section-inner-32)
    color: var(--c-text-default)
    .accordion--button_text
      margin: 0
    .icon
      position: absolute
      left: 0
      display: flex
      align-items: center
      justify-content: center
      flex-shrink: 0
      flex-grow: 0
      height: 1.33em
      width: 1.33em
      transform: rotate(0deg)
      transition: var(--transition)
      color: rgba(255,255,255, 0.4)
      &:before
        content: "\e90d"
  &--content
    padding: 0 var(--g-section-inner-24)
    color: var(--c-text-subdued)
    &.is_cascade
      & > *
        color: var(--c-primary)
        &:last-child
          padding-bottom: var(--g-section-inner-24)
      & > p:last-child
        margin: 0



