/* Common styles for button */
.button
  position: relative
  display: flex
  align-items: center
  justify-content: center
  gap: 8px
  width: fit-content
  padding: 10px 24px
  line-height: 1.3
  font-weight: 500
  font-size: clamp(13px, calc(13px + (16 - 13) * ((100vw - 768px) / (1920 - 768))), 16px)
  border: 1px solid transparent
  border-radius: var(--border-corner-button)
  cursor: pointer
  z-index: 1
  overflow: hidden
  transition: var(--transition)
  span
    color: inherit
    line-height: 1.3
    text-align: center
    position: relative
    z-index: 2
    font-weight: 400
    //color: #613cc319
    transition: var(--transition)
    white-space: nowrap

  span[class^="icon-"],
  span[class*=" icon-"],
  svg[class^="icon"]
    color: inherit
    width: 1em
    height: 1em
    line-height: 1
    font-size: 1.3em
    aspect-ratio: 1 / 1
    transition: var(--transition)
    display: flex
    &:only-child
      margin: 0 -8px


/* Button appearance */

.button.button-filled
  border-radius: var(--border-corner-button)

  background: var(--filled-bgc-base)
  border-color: transparent
  color: var(--filled-c-base)
  &:hover,
  &:focus,
  &:active
    background: var(--filled-bgc-hover)
    //border-color: var(--filled-bgc-hover)
    color: var(--filled-c-hover)


.button.button-outlined
  border-radius: var(--border-corner)

  background-color: transparent
  border-color: var(--outlined-bc-base)
  color: var(--outlined-c-base)
  &:hover,
  &:focus,
  &:active
    background-color: var(--outlined-bgc-hover)
    border-color: var(--outlined-bc-hover)
    color: var(--outlined-c-hover)


.button.button-link
  border: none
  border-radius: 0
  justify-content: flex-start
  padding: 8px 0
  color: var(--link-c-base)
  background: transparent
  text-decoration: none
  span[class^="icon-"],
  span[class*=" icon-"],
  svg[class^="icon"]
    &:only-child
      margin: 0

  &:visited
    opacity: 0.8
  &:hover,
  &:focus,
  &:active
    color: var(--link-c-hover)


/* Button  colors  */

body

  .button.button-accent
    --filled-bgc-base: var(--c-accent)
    --filled-c-base: var(--c-black)
    --filled-bgc-hover: var(--c-accent-hover)
    --filled-c-hover: var(--c-black)

    --outlined-bc-base: var(--c-accent)
    --outlined-c-base: var( --c-white)
    --outlined-bc-hover: var(--c-accent-hover)
    --outlined-c-hover: var( --c-white)

    --link-c-base: var( --c-white)
    --link-c-hover: var(--c-primary-hover)

  .button.button-primary
    --filled-bgc-base: var(--c-primary)
    --filled-c-base: var(--c-black)
    --filled-bgc-hover: var(--c-primary-hover)
    --filled-c-hover: var(--c-black)

    --outlined-bc-base: var(--c-primary)
    --outlined-c-base: var( --c-white)
    --outlined-bgc-hover: var(--c-primary-hover)
    --outlined-bc-hover: var(--c-primary-hover)
    --outlined-c-hover: var( --c-white)

    --link-c-base: var(--c-primary)
    --link-c-hover: var(--c-primary-hover)

  .button.button-secondary
    --filled-bgc-base: var(--c-secondary)
    --filled-c-base: var(--c-white)
    --filled-bgc-hover: var(--c-secondary-hover)
    --filled-c-hover: var(--c-white)

    --outlined-bc-base: var(--c-secondary)
    --outlined-c-base: var(--c-white)
    --outlined-bgc-hover: var(--c-secondary-hover)
    --outlined-bc-hover: var(--c-secondary-hover)
    --outlined-c-hover: var(--c-white)

    --link-c-base: var(--c-text-secondary)
    --link-c-hover: var(--c-white)
