.content_widget
  .card
    background-color: #1E1D1E
    border: 1px solid var(--c-secondary)
    &--image,img
      aspect-ratio: 544/306
    &--content
      display: flex
      flex-direction: column
      height: 100%
      padding: var(--g-section-inner-32)
    &--actions
      display: flex
      gap: 8px
      margin-top: auto
      .button
        margin-top: var(--g-section-inner-24)
