@font-face
  font-family: 'icomoon'
  src:  url('../fonts/icomoon/fonts/icomoon.eot')
  src:  url('../fonts/icomoon/fonts/icomoon.eot') format('embedded-opentype'), url('../fonts/icomoon/fonts/icomoon.ttf') format('truetype'), url('../fonts/icomoon/fonts/icomoon.woff') format('woff'),  url('../fonts/icomoon/fonts/icomoon.svg') format('svg')
  font-weight: normal
  font-style: normal
  font-display: block

[class^="icon-"], [class*=" icon-"], .icon
  font-family: 'icomoon' !important
  speak: never
  font-style: normal
  font-weight: normal
  font-variant: normal
  text-transform: none
  line-height: 1
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale

.icon-ic-widjet:before
  content: "\e94e"


.icon-ic-question:before
  content: "\e94c"

.icon-ic-burger:before
  content: "\e94d"

.icon-ic-arrow-down:before
  content: "\e900"

.icon-ic-arrow-left:before
  content: "\e901"

.icon-ic-arrow-right:before
  content: "\e902"

.icon-ic-arrow-up:before
  content: "\e903"

.icon-ic-arrow-up-right:before
  content: "\e904"

.icon-ic-bell:before
  content: "\e905"

.icon-ic-book:before
  content: "\e906"

.icon-ic-bookmark:before
  content: "\e907"

.icon-ic-book-open:before
  content: "\e908"

.icon-ic-calendar:before
  content: "\e909"

.icon-ic-check:before
  content: "\e90a"

.icon-ic-chevron-down:before
  content: "\e90b"

.icon-ic-chevron-left:before
  content: "\e90c"

.icon-ic-chevron-right:before
  content: "\e90d"

.icon-ic-chevrons-down:before
  content: "\e90e"

.icon-ic-chevrons-left:before
  content: "\e90f"

.icon-ic-chevrons-right:before
  content: "\e910"

.icon-ic-chevrons-up:before
  content: "\e911"

.icon-ic-chevron-up:before
  content: "\e912"

.icon-ic-copy:before
  content: "\e913"

.icon-ic-edit-2:before
  content: "\e914"

.icon-ic-edit-3:before
  content: "\e915"

.icon-ic-eye:before
  content: "\e916"

.icon-ic-filter:before
  content: "\e917"

.icon-ic-folder:before
  content: "\e918"

.icon-ic-globe:before
  content: "\e919"

.icon-ic-info:before
  content: "\e91a"

.icon-ic-link-2:before
  content: "\e91b"

.icon-ic-list:before
  content: "\e91c"

.icon-ic-mail:before
  content: "\e91d"

.icon-ic-map:before
  content: "\e91e"

.icon-ic-map-pin:before
  content: "\e91f"

.icon-ic-maximize:before
  content: "\e920"

.icon-ic-maximize-2:before
  content: "\e921"

.icon-ic-message-circle:before
  content: "\e922"

.icon-ic-message-square:before
  content: "\e923"

.icon-ic-minimize:before
  content: "\e924"

.icon-ic-minus:before
  content: "\e925"

.icon-ic-moon:before
  content: "\e926"

.icon-ic-more-horizontal:before
  content: "\e927"

.icon-ic-more-vertical:before
  content: "\e928"

.icon-ic-paperclip:before
  content: "\e929"

.icon-ic-pause:before
  content: "\e92a"

.icon-ic-phone:before
  content: "\e92b"

.icon-ic-play:before
  content: "\e92c"

.icon-ic-plus:before
  content: "\e92d"

.icon-ic-qr:before
  content: "\e92e"

.icon-ic-quote:before
  content: "\e92f"

.icon-ic-select-chevrones:before
  content: "\e930"

.icon-ic-send:before
  content: "\e931"

.icon-ic-settings:before
  content: "\e932"

.icon-ic-shopping-cart:before
  content: "\e933"

.icon-ic-sliders:before
  content: "\e934"

.icon-ic-socials-dzen:before
  content: "\e935"

.icon-ic-socials-facebook:before
  content: "\e936"

.icon-ic-socials-instagram:before
  content: "\e937"

.icon-ic-socials-odnoklassniki:before
  content: "\e938"

.icon-ic-socials-telegram:before
  content: "\e939"

.icon-ic-socials-viber:before
  content: "\e93a"

.icon-ic-socials-vk:before
  content: "\e93b"

.icon-ic-socials-whatsapp:before
  content: "\e93c"

.icon-ic-socials-youtube:before
  content: "\e93d"

.icon-ic-sun:before
  content: "\e93e"

.icon-ic-tag:before
  content: "\e93f"

.icon-ic-tiangle-chevron-down:before
  content: "\e940"

.icon-ic-tiangle-chevron-left:before
  content: "\e941"

.icon-ic-tiangle-chevron-right:before
  content: "\e942"

.icon-ic-tiangle-chevron-up:before
  content: "\e943"

.icon-ic-trash:before
  content: "\e944"

.icon-ic-trash-2:before
  content: "\e945"

.icon-ic-upload-folder:before
  content: "\e946"

.icon-ic-user:before
  content: "\e947"

.icon-ic-volume:before
  content: "\e948"

.icon-ic-volume-x:before
  content: "\e949"

.icon-ic-x:before
  content: "\e94a"

.icon-ic-x-circle:before
  content: "\e94b"

