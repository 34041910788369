.content_banner-callback
  .banner
    display: flex
    overflow: hidden
    border: 1px solid var(--c-secondary)
    border-radius: var(--border-corner-card)
    position: relative
    @media(min-width: 999px)
      display: grid
      grid-template-columns: 5fr 7fr
    @media(min-width: 1140px)
      grid-template-columns: 6fr 6fr
    @media(min-width: 1241px)
      grid-template-columns: 7fr 5fr
      &:after
        content: ''
        position: absolute
        inset: 0
        background: linear-gradient( var(--c-secondary), transparent 1px), linear-gradient( 90deg, var(--c-secondary), transparent 1px)
        background-size: 42px 42px
        background-position: center right
        z-index: -1
        mask-image: linear-gradient(90deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 70%)
    .block--head,
    .block--actions
      padding: 0
    &--content
      padding: clamp(32px, calc(32px + (64 - 32) * ((100vw - 768px) / (1920 - 768))), 64px)
    &--image
      display: flex
      height: 100%
      @media(max-width: 998px)
        display: none


