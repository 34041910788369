.js-gallerySwiper
  display: flex
  width: 100%
  position: relative
  --c-text-default: #fff
  .gallery--item
    border-radius: var(--border-corner-card)
    overflow: hidden
    aspect-ratio: 640 / 400
    @media(max-width: 768px)
      aspect-ratio: 328 / 218
  .slider_controls
    display: flex
    justify-content: space-between
    position: absolute
    top: 50%
    left: 50%
    translate: -50% -50%
    width: calc(100% - var(--g-section-inner-32))
    align-items: center
    color: #fff
    z-index: 5
    pointer-events: none
    .swiper-next
      background-color: var(--c-primary)
      border-radius: var(--border-corner)
      padding: 10px
      &:after
        content: '\e90d'
        color: var(--c-black)
    .swiper-prev
      background-color: var(--c-primary)
      border-radius: var(--border-corner)
      padding: 10px
      &:after
        content: '\e90c'
        color: var(--c-black)

