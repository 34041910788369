.block_element.content_article,
.block_element.content_blog
  padding-top: 0
  .block--article_head
    margin: var(--g-section-inner) auto  0
    &_title
      font-size: var(--fs-heading-xl)
      margin-bottom: 12px
    &_subtitle
      font-size: var(--fs-body-sx)
      color: var(--c-text-secondary)
  article.is_cascade,
  .block--article_head
    max-width: 640px
    width: 100%
  article
    margin: var(--g-section-inner-48) auto  0
    .block--head
      max-width: 100%

.is_cascade
  --margin-xl: clamp(48px, calc(48px + (56 - 48) * ((100vw - 768px) / (1920 - 768))), 56px)
  --margin-l: clamp(24px, calc(24px + (36 - 24) * ((100vw - 768px) / (1920 - 768))), 36px)
  --margin-m: clamp(20px, calc(20px + (24 - 20) * ((100vw - 768px) / (1920 - 768))), 24px)


  font-size: var(--fs-body-sm)
  color: var(--c-primary)
  & > h1, & > h2, & > h3, & > h4, & > h5, & > h6
    margin: var(--g-elements) 0
    font-weight: 700
    color: var(--c-text-default)
    scroll-margin-top: 100px
    line-height: 1.1

  & > h1
    margin: var(--margin-xl) 0 var(--margin-l)
    font-size: var(--fs-heading-xxl)
    &:first-child
      margin-top: 0
  & > h2
    margin: var(--margin-xl) 0 var(--margin-m)
    font-size: var(--fs-heading-xl)
  & > h3
    margin: var(--margin-xl) 0 var(--margin-m)
    font-size: var(--fs-heading-lg)
  & > h4
    margin: var(--margin-l) 0 var(--margin-m)
    font-size: var(--fs-heading-md)
  & > h5, h6
    margin: var(--margin-l) 0 var(--margin-m)
    font-size: var(--fs-body-md)
  p
    margin-bottom: var(--g-elements)
    & > strong,
    & > b
      font-weight: 700

  a:not(.button)
    text-decoration: underline
    &:hover,
    &:active
      color: var(--c-text-accent-hovered)
    &:visited
      opacity: 0.8
  & > strong
    //font-size: var(--fs-body-xl)
    font-weight: 400
  & ul,
  & ol
    margin: var(--g-elements) 0
    padding-left: 20px
    li
      &:not(:last-of-type)
        margin-bottom: 8px
  figure
    display: flex
    flex-direction: column
    justify-content: flex-start
    align-items: center
    margin: var(--g-elements) 0
    img, video
      width: 100%
      object-fit: cover
    figcaption
      color: var(--c-text-disabled)
      text-align: center
      font-size: 16px
      margin-top: 16px
  video, img, iframe, object
    object-fit: contain
    margin:  0 auto
    object-position: center
    width: 100%
  & > img,
  & > video,
  & > iframe,
  & > object
    margin: var(--g-elements) 0
    height: auto
  .is_cascade--slider
    margin: var(--g-elements) 0
    overflow: hidden
    figure
      height: 100%
      margin: 0
  blockquote
    margin: var(--g-elements) 0
    padding-left: var(--g-section-inner)
    border-left: 2px solid var(--c-text-default)
    display: flex
    flex-direction: column
    gap: var(--g-elements)
    .icon-ic-quote
      font-size: 24px
    p
      //font-size: var(--fs-body-xl)
      line-height: 1.5
      margin: 0
    .blockquote--author
      display: grid
      grid-template-columns: 48px 1fr
      gap: 0 16px
      img
        place-self: center
        grid-column: 1
        grid-row: span 2
        width: 48px
        height: 48px
        object-fit: cover
        object-position: center
        border-radius: 50% !important
        overflow: hidden
      p
        margin: 0
        grid-column: 2
        font-size: var(--fs-body-md)
      span
        grid-column: 2
        font-size: 16px
        color: var(--c-text-subdued)
  .button_wrapper, .block--actions
    display: flex
    align-items: center
    gap: 8px
    margin: var(--g-elements) 0
  .is_cascade--accordion
    margin: var(--g-elements) 0
  &--table
    max-width: 100vw
    overflow-x: auto
    margin: var(--g-elements) 0
    @media(max-width: 768px)
  table
    border-collapse: collapse
    width: 100%
    border: 1px solid var(--c-secondary-hover)
    text-align: left
    thead
      background-color: var(--c-base-bgc)
    thead,
    tbody th
      position: sticky
      font-weight: 700
      text-align: left
    tbody th:only-of-type
      left: 0
    tbody th:not(:only-of-type)
      top: 0
    thead
      top: 0
    th
      max-width: 30%
      background-clip: padding-box
      background-color: var(--c-secondary)
      box-shadow: 6px 0px 13px 0px rgba(0, 0, 0, 0.02),23px 0px 23px 0px rgba(0, 0, 0, 0.02),53px 0px 32px 0px rgba(0, 0, 0, 0.01), 93px 0px 37px 0px rgba(0, 0, 0, 0), 146px 0px 41px 0px rgba(0, 0, 0, 0)
    td, th
      border: 1px solid var(--c-secondary-hover)
      padding: 8px 16px
