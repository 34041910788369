.block--head
  display: flex
  flex-direction: column
  align-items: flex-start
  gap: 16px
  color: var(--c-text-default)
  font-size: var(--fs-body-sx)
  @media(max-width: 768px)
    gap: 12px
  &_subtitle
    width: fit-content
    color: var(--c-accent)
    &:empty
      display: none
    p
      &:empty
        display: none
  &_title
    font-size: clamp(28px, calc(28px + (44 - 28) * ((100vw - 768px) / (1920 - 768))), 44px)
  &_caption
    opacity: 0.85
    font-size: var( --fs-body-sm)
    &:empty
      display: none
    p
      &:empty
        display: none


