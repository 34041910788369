.content_blog
  .block--elements
    @media(min-width: 769px)
      grid-template-columns: repeat(3, 1fr)
      .block--pagination
        grid-column: span 3
  .card
    gap: clamp(12px, calc(12px + (20 - 12) * ((100vw - 768px) / (1920 - 768))), 20px)
    border-radius: 0
    &--content
      padding: 0
    &--image, img
      aspect-ratio: 352/ 198
      border-radius: var(--border-corner-card)
    &--title
      font-size: var(--fs-body-sm)
