.content_advantages
  padding: var(--g-section-inner) var(--container-padding)
  .block--wrapper
    display: grid
    gap: 0 var(--g-section-inner-64)
    grid-template-columns: 1fr
    grid-template-rows: auto
    grid-template-areas: 'heading' 'elements' 'image'
    padding: 0
    @media(min-width: 993px)
      grid-template-columns: 1fr 1fr
      grid-template-areas: 'heading image' 'elements image'
      grid-template-rows: auto 1fr

  &.content_advantages-reversed
    @media(min-width: 993px)
      .block--wrapper
        grid-template-areas: 'image heading' 'image elements'
  .block--elements
    display: flex
    flex-direction: column
    gap: var(--g-section-inner-24)
  .block--image
    grid-area: image
    height: 100%
    position: relative
    @media(max-width: 768px)
      display: none
    .advantages--item_image
      display: none
      @media(min-width: 768px)
        position: sticky
        top: calc(var(--headerHeight) + 40px)
      &.is_active
        display: flex

.advantages
  &--item
    position: relative
    padding-left: 24px
    opacity: 0.4
    @media(min-width: 769px)
      padding-left: 36px
    &:after
      content: ''
      position: absolute
      height: 100%
      width: 2px
      background-color: var(--c-accent)
      border-radius: 100px
      left: 0
      top: 0

    &_wrapper
      position: relative
    @media(min-width: 769px)
      .advantages--item_image
        display: none !important
    &.is_active
      .advantages--item_image
        display: block
      .advantages--item_content
        pointer-events: auto
        height: auto
        opacity: 1
      .icon
        transform: rotate(180deg)
        transform-origin: center
    &_image
      display: none
      img, video
        aspect-ratio: 1 / 1
        object-fit: cover
        height: auto
        width: 100%
        border-radius: var(--border-corner-small)
        overflow: hidden
      &.is_active
        img, video
          opacity: 1
          display: flex
    &_button
      cursor: pointer
      display: flex
      justify-content: space-between
      align-items: flex-start
      position: relative
      padding: var(--g-section-inner-24) 0
      gap: 16px
      color: var(--c-text-default)
      &_text
        margin: 0
        transition: var(--transition)
        font-weight: 700
        font-size: var(--fs-heading-md)
      .icon
        display: flex
        align-items: center
        justify-content: center
        flex-shrink: 0
        flex-grow: 0
        height: 1.33em
        width: 1.33em
        font-size: var(--fs-heading-md)
        transform: rotate(0deg)
        transition: var(--transition)
        &:before
          content: "\e940"
    &_content
      height: 0
      opacity: 0
      pointer-events: none
      & > *:first-child
        margin-top: 0
      & > *:last-child
        padding-bottom: 24px
        margin-bottom: 0
    &.is_active
      opacity: 1


.content_advantages-icon
  .block--elements
    grid-template-columns: repeat(3, 1fr)
    gap: 32px
    @media(max-width: 768px)
      gap: 24px
      display: flex
      flex-direction: column
  .card
    display: grid
    grid-template-columns: 15% 85%
    border-radius: 0
    @media(min-width: 769px)
      display: flex
      flex-direction: column
    &--image
      width: 100%
      max-width: 48px
      aspect-ratio: 1/1
      object-fit: contain
    &--content
      display: flex
      flex-direction: column
      gap: 8px
    &--title
      font-weight: 700
      font-size: var( --fs-body-md)
    &--text
      color: var( --c-text-secondary)
      font-size: var( --fs-body-sm)
  .card:not(:last-child)
    @media(max-width: 768px)
      padding-bottom: 24px
      border-bottom: 1px solid var(--c-secondary)
  .card:not(:nth-child(3n + 3))
    @media(min-width: 769px)
      border-right: 1px solid var(--c-secondary)
      padding-right: 32px

