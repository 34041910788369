.footer
  padding: 40px var(--container-padding)
  position: relative
  display: flex
  flex-direction: column
  gap: var(--g-vertical)
  color: var(--c-text-disabled)
  z-index: 2
  border-top: 1px solid var( --c-secondary)
  @media(min-width: 768px)
    padding: var(--g-section-inner-64)  var(--container-padding)
  &--main
    display: flex
    flex-direction: column-reverse
    gap: 40px
    @media(min-width: 768px)
      display: grid
      gap: var(--g-section-inner-32, 32px)
      grid-template-columns: auto 1fr
  &--content
    display: flex
    flex-direction: column
    gap: 16px
.footer--main_title
  color: var(--c-white, #fff)
  margin-bottom: 12px
  font-size: var( --fs-body-sm)
  font-weight: 700

.footer--nav
  display: grid
  grid-template-columns:  repeat(2, 1fr)
  @media(max-width: 992px)
    gap: 12px
  @media(min-width: 768px)
    grid-template-columns:  repeat(2, 1fr)
  @media(min-width: 1240px)
    grid-template-columns:  repeat(4, 1fr)

.footer--nav_col_list
  grid-area: column
  list-style: none
  display: flex
  flex-direction: column
  gap: 12px
  .button
    font-size: var(--fs-body-sx)
    padding: 0
.copyright
  color: var(--c-text-disabled)
  font-size: clamp(11px, calc(11px + (14 - 11) * ((100vw - 768px) / (1920 - 768))), 14px)
