.block_image_text
  padding: var(--g-section-inner) var(--container-padding)
  max-width: 100vw
  --padding: clamp(16px, calc(16px + (64 - 16) * ((100vw - 768px) / (1920 - 768))), 64px)
  --padding-text: clamp(0px, calc(0px + (64 - 0) * ((100vw - 768px) / (1920 - 768))), 64px)
  .block--wrapper
    padding: 0
    display: grid
    grid-template-columns: 1fr
    place-content: start
    place-items: center
    gap: 16px
    grid-template-areas: 'image' 'content'
    @media(min-width: 768px)
      grid-template-areas: 'content image'
      grid-template-columns: 1fr 1fr
  .block--content
    margin-top: 0
    width: 100%
    padding-right: var(--padding-text)
    display: flex
    flex-direction: column
    justify-content: center
    gap: 24px 16px
    &:only-child
      padding-right: 0
      display: grid
      grid-template-rows: auto  1fr
      place-items: start
      grid-column: span 2
      @media(min-width: 768px)
        grid-template-columns: 1fr 1fr
        & > .block--head
          grid-column: 1
        & > .is_cascade
          grid-column: 2
          grid-row: span 2
        & > .block--actions
          grid-column: 1
      .block--head,
      .block--actions
        padding: 0 var(--padding-text) 0 0
  .block--image ~ .block--content
    grid-area: content
  .block--image
    grid-area: image
    min-height: 100%
    max-width: 100%
    width: 100%
    display: flex
    img, video
      object-fit: cover
      height: auto
      width: 100%
      //border-radius: var(--border-corner-card)
      overflow: hidden
      transform: translateZ(0)
      @media(min-width: 992px)
        position: sticky
        top: calc(var(--headerHeight, 90px) + 40px)
  .block--head
    align-items: flex-start
    h1:not([class]),
    h2:not([class]),
    h3:not([class])
      font-size: var(--fs-3xl)
  .block--content
    & > .is_cascade
      & > *:first-child
        margin-top: 0
      & > *:last-child
        margin-bottom: 0

  &.block_image_text-reversed
    @media(min-width: 768px)
      .block--wrapper
        grid-template-areas: 'image content'
    .block--content
      padding-left: var(--padding-text)
      padding-right: 0


  &[class*="block_image_text-bg"]
    .block--wrapper
      padding: var(--padding)
      background-color: var(--bg-color)
      border-radius: var(--border-radius-2xl)

  //&.block_image_text-bg
  //  &_dark
  //    @extend .-mode-dark
  //    --bg-color: var(--bg-surface-3)
  //    color: var(--fg-default)
  //  &_light
  //    @extend .-mode-light
  //    color: var(--fg-default)
  //    --bg-color: var(--bg-surface-1)
  //  &_neutral
  //    --bg-color: var(--bg-surface-2)
  //  &_accent
  //    --bg-color: var(--accent-container-soft)

  &.block_image_text-ratio_square
    .block--image
      img, video
        aspect-ratio: 1 / 1

  &.block_image_text-ratio_portrait
    .block--image
      img, video
        aspect-ratio: 3 / 4
  &.block_image_text-ratio_landscape
    .block--image
      img, video
        aspect-ratio: 16 / 9

  &.block_image_text-ratio_fill
    .block--image
      width: 100%
      height: 100%
      overflow: hidden
      position: relative
      @media(max-width: 768px)
        aspect-ratio:  1 / 1
      img, video
        position: absolute
        top: 0
        left: 0
        aspect-ratio: unset
        width: 100%
        height: 100%

  &.block_image_text-ratio_wide
    padding: 0
    &[class*="block_image_text-bg"]
      .block--wrapper
        border-radius: 0
        padding: 0
        @media(min-width: 768px)
          padding-right: 0
      &.block_image_text-reversed
        .block--wrapper
          padding-left: 0
    &.block_image_text-reversed
      .block--content
        @media(min-width: 768px)
          padding-left: var(--padding-text)
          padding-right: var(--container-padding)
    .block--content
      padding: 0 var(--container-padding) 24px
      @media(min-width: 768px)
        padding-left: var(--container-padding)
        padding-right: var(--padding-text)
        padding-top: var(--padding-text)
        padding-bottom: var(--padding-text)
    .block--image
      position: relative
      width: 100%
      height: 100%
      @media(max-width: 768px)
        aspect-ratio:  1 / 1
    img, video
      border-radius: 0
      position: absolute
      top: 0
      left: 0
      aspect-ratio: unset
      width: 100%
      height: 100%
