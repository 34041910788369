.block--pagination
  display: flex
  justify-content: center
  grid-column: span 2
  gap: 8px
  @media(max-width: 768px)
    grid-column: span 1

  .button
    aspect-ratio: 1/1
    padding: 10px
    border: 1px solid var(--c-secondary)
    border-radius: var(--border-corner-button)
    width: auto
    height: 40px
    color: var(--c-secondary)
    @media(min-width: 769px)
      height: 44px
    &.is_current
      color: var(--c-white)
    &:has([class*=icon-])
      color: var(--c-white)
      border: none
      background-color: var(--c-secondary)

  .button:only-child
    padding: 10px 24px
    aspect-ratio: auto
