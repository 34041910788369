.content_case
  .card
    border: 1px solid var(--c-secondary)
    &--title
      padding: var(--g-section-inner-32)
    &--image, img
      aspect-ratio: 544/ 310
    &--content
      padding: 0
      &_item
        display: grid
        grid-template-columns: 1fr 2fr
        padding: var(--g-section-inner-24) var(--g-section-inner-32)
        border-top: 1px solid var(--c-secondary)
        font-size: var(--fs-body-sx)
