html
  --container-padding: calc((100vw - 1120px) / 2)
  @media(min-width: 2024px)
    --container-padding: calc( (100vw - 1920px - 104px) / 2)
  @media(max-width: 1160px)
    --container-padding: 32px
  @media(max-width: 768px)
    --container-padding: 24px
  --gap: 20px
  @media(max-width: 1024px)
    --gap: 16px

  --max-width: 1120px
  //font
  --fs-heading-xxl: clamp(42px, calc(42px + (72 - 42) * ((100vw - 768px) / (1920 - 768))), 72px)
  --fs-heading-xl: clamp(28px, calc(28px + (44 - 28) * ((100vw - 768px) / (1920 - 768))), 44px)
  --fs-heading-lg: clamp(18px, calc(18px + (32 - 18) * ((100vw - 768px) / (1920 - 768))), 32px)
  --fs-heading-md: clamp(16px, calc(16px + (24 - 16) * ((100vw - 768px) / (1920 - 768))), 24px)
  //--fs-heading-sm: clamp(14px, calc(14px + (20 - 14) * ((100vw - 768px) / (1920 - 768))), 20px)

  --fs-body-xl: clamp(18px, calc(18px + (44 - 18) * ((100vw - 768px) / (1920 - 768))), 44px)
  --fs-body-lg: clamp(18px, calc(18px + (24 - 18) * ((100vw - 768px) / (1920 - 768))), 24px)
  --fs-body-md: clamp(16px, calc(16px + (20 - 16) * ((100vw - 768px) / (1920 - 768))), 20px)
  --fs-body-sm: clamp(15px, calc(15px + (18 - 15) * ((100vw - 768px) / (1920 - 768))), 18px)
  --fs-body-sx: clamp(13px, calc(13px + (16 - 13) * ((100vw - 768px) / (1920 - 768))), 16px)

  //// indent
  --g-section-inner-16: clamp(8px, calc(8px + (16 - 8) * ((100vw - 768px) / (1920 - 768))), 16px)
  --g-section-inner: clamp(36px, calc(36px + (48 - 36) * ((100vw - 768px) / (1920 - 768))), 48px)
  --g-section-inner-24: clamp(20px, calc(20px + (24 - 20) * ((100vw - 768px) / (1920 - 768))), 24px)
  --g-section-inner-32: clamp(24px, calc(24px + (32 - 24) * ((100vw - 768px) / (1920 - 768))), 32px)
  --g-section-inner-48: clamp(24px, calc(24px + (48 - 24) * ((100vw - 768px) / (1920 - 768))), 48px)
  --g-section-inner-56: clamp(40px, calc(40px + (56 - 40) * ((100vw - 768px) / (1920 - 768))), 56px)
  --g-section-inner-64: clamp(24px, calc(24px + (64 - 24) * ((100vw - 768px) / (1920 - 768))), 64px)
  --g-section-inner-80: clamp(32px, calc(32px + (80 - 32) * ((100vw - 768px) / (1920 - 768))), 80px)
  --g-elements: clamp(20px, calc(20px + (32 - 20) * ((100vw - 768px) / (1920 - 768))), 32px)
  --g-vertical: clamp(36px, calc(36px + (72 - 36) * ((100vw - 768px) / (1920 - 768))), 72px)


  // corner
  --border-corner-button: 10px
  --border-corner: 10px
  --border-corner-circle: 50%
  --border-corner-card: 16px



  // color
  --c-base-bgc: #121112
  --c-white: #fff
  --c-text-default: #fff
  --c-black: #000
  --c-text-disabled: rgba(255,255,255, 0.25)
  --c-text-secondary: rgba(255,255,255, 0.60)
  --c-critical: #E5325D
  // color btn
  --c-accent: #CBFF5C
  --c-accent-hover: #DBFF8D
  --c-primary: rgba(255,255,255, 0.80)
  --c-primary-hover: rgba(255,255,255, 0.70)
  --c-secondary: rgba(255,255,255, 0.1)
  --c-secondary-hover: rgba(255,255,255, 0.25)




  --transition: 0.2s cubic-bezier(0, 0, 0.58, 1)
