
@font-face
  font-family: 'huaora'
  font-weight: 125 950
  font-style: normal
  src: url("../fonts/hauora/HauoraGX.ttf") format('truetype')


html
  font-size: var(--fs-body-md)
  line-height: 1.3
  letter-spacing: 0.014em
  font-weight: 400
  font-family: 'huaora', sans-serif
  color: var(--c-white)
  background-color: var(--c-base-bgc)
  text-size-adjust: none
  -webkit-text-size-adjust: none
  scroll-behavior: smooth
h1, h2, h3, h4, h5, h6
  font-weight: 700
  line-height: 1.14
  letter-spacing: -0.01em
  font-family: 'Unbounded', sans-serif

.f-h1
  font-size: clamp(36px, calc(36px + (60 - 36) * ((100vw - 768px) / (1920 - 768))), 60px)

.f-h2
  font-size: clamp(28px, calc(28px + (44 - 28) * ((100vw - 768px) / (1920 - 768))), 44px)

.f-h3
  font-size: clamp(24px, calc(24px + (32 - 24) * ((100vw - 768px) / (1920 - 768))), 32px)



