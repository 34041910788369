.block_list
  .block--elements
    display: flex
    flex-direction: column
    gap: var(--g-section-inner) var(--gap)
    margin-top: var(--g-section-inner)
    @media(min-width: 768px)
      display: grid
      grid-template-columns: repeat(2, 1fr)
      .block--pagination
        grid-column: span 2
    .card
      width: 100%



.block_list-slider
  position: relative
  overflow: hidden
  .block--elements
    display: flex
    position: relative
    padding-bottom: 80px


.block_list-slider
  .slider_controls
    padding: 0
    position: absolute
    width: fit-content
    bottom: 0
    left: 50%
    translate: -50% 0
    display: flex
    gap: 8px
    justify-content: space-between









