.content_blog-hero
  .block--elements
    overflow: hidden
    position: relative
    display: flex
    margin-top: var(--g-section-inner)
  .card
    gap: var(--g-section-inner-48)
    border-radius: 0
    display: flex
    flex-direction: column
    @media(min-width: 769px)
      display: grid
      grid-template-columns: 7fr 5fr

    &--image,img
      border-radius: var(--border-corner-card)
      aspect-ratio: 640/360
    &--content
      padding: 0
      display: flex
      flex-direction: column
      height: 100%
    &--title
      font-size: var(--fs-heading-lg)
      -webkit-line-clamp: 3
    &--text
      margin-top: 8px
      font-size: var(--fs-body-sm)
    &--actions
      margin-top: auto
      .button
        margin-top: 28px
  .slider_controls
    position: absolute
    right: 0
    bottom: 0
    display: flex
    gap: 8px
    z-index: 10
    pointer-events: all
.content_blog-hero
  padding-bottom: 0
.content_blog-hero ~ .content_blog
  padding-top: 0
