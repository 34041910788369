.header
  position: fixed
  top: 0
  left: 0
  width: 100%
  max-width: 100vw
  z-index: 50
  padding: 14px var(--container-padding)
  background-color: var(--c-base-bgc)
  border-bottom: 1px solid var(--c-secondary)
  border-bottom: 1px solid var(--c-secondary)
  //height: 60px
  @media(min-width: 769px)
    padding: 20px var(--container-padding)
  //  height: 84px

.header--main
  display: flex
  align-items: center
  justify-content: space-between
  gap: 32px

.header--logo
  display: flex
  align-items: center
  svg
    fill: var(--logo-color)

.header--nav
  display: none
  @media(min-width: 769px)
    flex-grow: 1
    display: flex
    align-items: center
    justify-content: center

.nav
  display: flex
  justify-content: center
  align-items: center
  gap: 4px
  &--item
    position: relative
    display: flex
    &_button
      display: flex
      align-items: center
      font-size: var(--fs-body-sx)
      padding: 0px 10px
      line-height: 1.33
      gap: 8px
      backdrop-filter: blur(20px)
      cursor: pointer
      transition: var(--transition)
      @media(max-width: 768px)
        margin-bottom: 8px
      span
        line-height: 1.33


.nav
  &--item
    color: var(--c-text-secondary)
    & [class^=icon-]
      transition: var(--transition)
    &.is_open
      color: var(--c-white)
      & [class^=icon-]
        transform: scale(1, -1)
.header:not(.header-documentation)
  .nav
    &--item
      &_button
      &:hover,
      &:focus,
      &:active,
      &:focus-within,
      &.is_open
        color: var(--c-white)
        opacity: 1
        & .nav--item_popover
          display: flex
          opacity: 1
          pointer-events: all
          @media(max-width: 768px)
            animation: slidein 2s forwards
          & .nav--item_popover_content
            opacity: 1
            transform: translateY(0)

        & [class^=icon-]
          transform: scale(1, -1)

.header.is_menu_opened
  position: fixed
  .icon-ic-burger
    display: none
  .icon-ic-x
    display: flex

.header:not(.header-documentation)
  .nav--item_popover
    display: flex
    opacity: 0
    pointer-events: none
    position: absolute
    top: 100%
    left: 50%
    padding: 20px 0
    transform: translateX(-50%)
    &_content
      min-width: 232px
      background: #2A292A
      border: 1px solid  #FFFFFF1A
      border-radius: var(--border-corner)
      opacity: 0
      transform: translateY(8px)
      transition: var(--transition)



.nav--list
  padding: 4px
  list-style: none
  &_item
    padding: 8px 12px
    display: flex
    gap: 12px
    align-items: center
    justify-content: flex-start
    border-radius: 8px
    transition: var(--transition)
    font-size: var(--fs-body-sx)
    &:hover,
    &:active,
    &:focus
      background: var(--c-secondary)


.header--actions
  display: flex
  position: relative
  gap: 16px
  .button:not([data-openpopup])
    @media(max-width: 768px)
      padding: 7px 16px
      span
        line-height: 1.1em
  [class^=icon-]
   transition: var(--transition)
  .icon-ic-x
    display: none
.menu_button
  font-size: 24px
  padding: 0
  @media(min-width: 769px)
    display: none

.header-simple
  .header--main
    justify-content: center

.header-documentation
  @media(min-width: 999px)
    position: static
    height: 100%
    padding: var(--g-section-inner-32) var(--g-section-inner-24)
    background-color: var(--c-base-bgc)
  .header--main
    @media(min-width: 999px)
      top: var(--g-section-inner-32)
      position: sticky
      align-items: flex-start
      flex-direction: column
  .menu_button
    @media(max-width: 998px)
      display: flex
.nav-documentation.nav
  display: none
  @media(min-width: 999px)
    display: flex
    flex-direction: column
    align-items: flex-start
    .nav--item
      display: flex
      flex-direction: column
      color: var(--c-text-secondary)
    .nav--item.is_open
      .nav--item_button
        color: #FFFFFF
      .nav--item_popover
        animation: slidein 2s forwards
    .nav--item_button
      padding: 5px 8px
      font-size: 13px
      @media(min-width: 769px)
        font-size: 15px
    .nav--item_popover
      max-height: 0
      overflow: hidden
      pointer-events: none
      font-size: 13px
      padding-left: 14px
      @media(min-width: 769px)
        font-size: 15px
    .nav--list
      padding: 6px 0
      &_item
        color: var(--c-text-secondary)
        border-radius: 0
        border-left: 1px solid var(--c-text-secondary)
        &.is_current
          color: #fff
          border-color: #fff
