.breadcrumbs
  padding: var(--g-section-inner) var(--container-padding)
  display: flex
  align-items: center
  z-index: 5
  width: 100%
  flex-wrap: nowrap
  max-width: 100vw
  overflow-x: auto
  a
    transition: var(--transition)
    position: relative
    color: var(--c-text-default)
    white-space: nowrap
    padding: 0 16px
    font-size: var(--fs-body-sx)
    &:not(:last-child):after
      content: ">"
      position: absolute
      right: 0
      translate: 50% 0
    &:first-child
      padding-left: 0
    &:last-child
      pointer-events: none
      color: var(--c-text-disabled)
    &:hover,
    &:focus,
    &:active
      color: var(--c-text-default)

.header-transparent
  & ~ main >  .breadcrumbs
    position: absolute
    top: var(--headerHeight)
    padding: var(--g-section-inner) calc(var(--container-padding) + (var(--column) + var(--gap)) * 2)
    left: 0

