.content_plans

  .block--elements
    grid-template-columns: 1fr
    gap: var(--g-section-inner-32)
    position: relative
    @media(min-width: 769px)
      grid-template-columns: repeat(3,  1fr)
      .block--pagination
        grid-column: span 3
    &:after
      content: ''
      position: absolute
      translate: -50% -50%
      top: 50%
      left: 50%
      backdrop-filter: blur(228px)
      width: 80%
      height: 100%
      z-index: -1
  .card
    aspect-ratio: 328/298
    backdrop-filter: blur(40px)
    border: 1px solid var(--c-secondary)
    @media(min-width: 769px)
      aspect-ratio: 352/364
    &--content
      padding: var(--g-section-inner-32)
      height: 100%
      display: flex
      flex-direction: column
    &--subtitle
      font-size: var(--fs-body-sx)
      margin-bottom: 8px
      font-weight: 600
    &--title
      display: flex
      align-items: flex-end
      margin-bottom: var(--g-section-inner-24)
      .card--price
        line-height: 1
        font-size: 32px
        margin-right: 4px
      .card--period
        font-size: var(--fs-heading-md)
        font-weight: 400
    &--text
      -webkit-line-clamp: initial
      ul
        display: flex
        flex-direction: column
        gap: 16px
        li
          display: flex
          gap: 12px
    &--actions
      margin-top: auto
      .button
        width: 100%
        margin-top: 16px
    &.is_accent
      border: none
      color: var(--c-black)
      background: linear-gradient(180deg, #CBFF5C 26.97%, #8BBE1C 100%)
      .card--title,.card--text
        color: var(--c-black)
      .button
        --filled-bgc-base: #121112
        --filled-c-base: #FFFFFF
        --filled-bgc-hover: #4D4D4D
        --filled-c-hover: #FFFFFF
.content_plans ~ .banner__callback
  padding-top: 0
