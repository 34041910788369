select
  -moz-appearance: none
  -webkit-appearance: none
  appearance: none
  display: none
  option
    display: none


.select
  position: relative
  &:hover
    .select--button
      border-color: var(--c-input-border-hover)
  &:has(.is_selected)
    .select--button
      border-color: var(--c-input-border-hover)
      color: var(--c-text-default)
  &--button
    border: 1px solid var(--c-input-border)
    background-color: var(--c-input-bg)
    border-radius: 8px
    padding: 12px 16px
    position: relative
    font-size: 16px
    cursor: pointer
    transition: var(--transition)
    color: var(--c-text-disabled)
    &:hover,
    &:focus
      border-color: var(--c-input-border-hover)
    &:after
      font-family: 'icomoon' !important
      content: "\e930"
      display: grid
      place-items: center
      width: 24px
      height: 100%
      position: absolute
      right: 10px
      top: 0
  &--option_wrapper
    opacity: 0
    pointer-events: none
    padding-top: 12px
    position: absolute
    width: 100%
    height: fit-content
    max-height: 320px
    z-index: 3
    &.is_opened
      opacity: 1
      pointer-events: all
      .select--option_list
        transform: translateY(0)
  &--option_list
    background-color: var(--c-base-bgc)
    transition: var(--transition)
    transform: translateY(-8px)
    display: flex
    flex-direction: column
    gap: 4px
    padding: 9px
    border: 1px solid var(--c-input-border)
    border-radius: 8px
    box-shadow: var(--box-shadow)
    color: var(--c-text-default)

  &--option
    padding: 8px 16px
    transition: var(--transition)
    cursor: pointer
    border-radius: 8px
    font-size: 16px
    display: flex
    align-items: center
    color: var(--c-text-default)
    &:hover,
    &:active,
    &:focus
      background-color: var(--c-secondary)
    &.is_selected
      background-color: rgba(202, 250, 101, 1)
      &:hover,
      &:active,
      &:focus
        background-color: rgba(202, 250, 101, 1)

