* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

button {
  border: none;
  background: transparent;
  color: inherit;
  font: inherit;
  cursor: pointer;
  text-decoration: none;
}

a {
  color: inherit;
  cursor: pointer;
  text-decoration: none;
}

html {
  -webkit-tap-highlight-color: transparent;
}

html {
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
}

body {
  margin: 0;
}

main {
  display: block;
}

h1, h2, h3, h4, h5, h6 {
  font-size: 1em;
  font-weight: 300;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

pre {
  font-family: monospace, monospace;
  font-size: 1em;
}

a {
  background-color: transparent;
}

abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  text-decoration: underline dotted;
}

b,
strong {
  font-weight: bolder;
}

code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

img {
  border-style: none;
}

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  border-style: none;
}

button:-moz-focusring,
[type=button]:-moz-focusring,
[type=reset]:-moz-focusring,
[type=submit]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

fieldset {
  padding: 0.35em 0.75em 0.625em;
}

legend {
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

textarea {
  overflow: auto;
}

[type=checkbox],
[type=radio] {
  box-sizing: border-box;
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

details {
  display: block;
}

summary {
  display: list-item;
}

template {
  display: none;
}

[hidden], .hidden {
  display: none;
}

html {
  --container-padding: calc((100vw - 1120px) / 2);
  --gap: 20px;
  --max-width: 1120px;
  --fs-heading-xxl: clamp(42px, calc(42px + (72 - 42) * ((100vw - 768px) / (1920 - 768))), 72px);
  --fs-heading-xl: clamp(28px, calc(28px + (44 - 28) * ((100vw - 768px) / (1920 - 768))), 44px);
  --fs-heading-lg: clamp(18px, calc(18px + (32 - 18) * ((100vw - 768px) / (1920 - 768))), 32px);
  --fs-heading-md: clamp(16px, calc(16px + (24 - 16) * ((100vw - 768px) / (1920 - 768))), 24px);
  --fs-body-xl: clamp(18px, calc(18px + (44 - 18) * ((100vw - 768px) / (1920 - 768))), 44px);
  --fs-body-lg: clamp(18px, calc(18px + (24 - 18) * ((100vw - 768px) / (1920 - 768))), 24px);
  --fs-body-md: clamp(16px, calc(16px + (20 - 16) * ((100vw - 768px) / (1920 - 768))), 20px);
  --fs-body-sm: clamp(15px, calc(15px + (18 - 15) * ((100vw - 768px) / (1920 - 768))), 18px);
  --fs-body-sx: clamp(13px, calc(13px + (16 - 13) * ((100vw - 768px) / (1920 - 768))), 16px);
  --g-section-inner-16: clamp(8px, calc(8px + (16 - 8) * ((100vw - 768px) / (1920 - 768))), 16px);
  --g-section-inner: clamp(36px, calc(36px + (48 - 36) * ((100vw - 768px) / (1920 - 768))), 48px);
  --g-section-inner-24: clamp(20px, calc(20px + (24 - 20) * ((100vw - 768px) / (1920 - 768))), 24px);
  --g-section-inner-32: clamp(24px, calc(24px + (32 - 24) * ((100vw - 768px) / (1920 - 768))), 32px);
  --g-section-inner-48: clamp(24px, calc(24px + (48 - 24) * ((100vw - 768px) / (1920 - 768))), 48px);
  --g-section-inner-56: clamp(40px, calc(40px + (56 - 40) * ((100vw - 768px) / (1920 - 768))), 56px);
  --g-section-inner-64: clamp(24px, calc(24px + (64 - 24) * ((100vw - 768px) / (1920 - 768))), 64px);
  --g-section-inner-80: clamp(32px, calc(32px + (80 - 32) * ((100vw - 768px) / (1920 - 768))), 80px);
  --g-elements: clamp(20px, calc(20px + (32 - 20) * ((100vw - 768px) / (1920 - 768))), 32px);
  --g-vertical: clamp(36px, calc(36px + (72 - 36) * ((100vw - 768px) / (1920 - 768))), 72px);
  --border-corner-button: 10px;
  --border-corner: 10px;
  --border-corner-circle: 50%;
  --border-corner-card: 16px;
  --c-base-bgc: #121112;
  --c-white: #fff;
  --c-text-default: #fff;
  --c-black: #000;
  --c-text-disabled: rgba(255,255,255, 0.25);
  --c-text-secondary: rgba(255,255,255, 0.60);
  --c-critical: #E5325D;
  --c-accent: #CBFF5C;
  --c-accent-hover: #DBFF8D;
  --c-primary: rgba(255,255,255, 0.80);
  --c-primary-hover: rgba(255,255,255, 0.70);
  --c-secondary: rgba(255,255,255, 0.1);
  --c-secondary-hover: rgba(255,255,255, 0.25);
  --transition: 0.2s cubic-bezier(0, 0, 0.58, 1);
}
@media (min-width: 2024px) {
  html {
    --container-padding: calc( (100vw - 1920px - 104px) / 2);
  }
}
@media (max-width: 1160px) {
  html {
    --container-padding: 32px;
  }
}
@media (max-width: 768px) {
  html {
    --container-padding: 24px;
  }
}
@media (max-width: 1024px) {
  html {
    --gap: 16px;
  }
}

@font-face {
  font-family: "huaora";
  font-weight: 125 950;
  font-style: normal;
  src: url("../fonts/hauora/HauoraGX.ttf") format("truetype");
}
html {
  font-size: var(--fs-body-md);
  line-height: 1.3;
  letter-spacing: 0.014em;
  font-weight: 400;
  font-family: "huaora", sans-serif;
  color: var(--c-white);
  background-color: var(--c-base-bgc);
  text-size-adjust: none;
  -webkit-text-size-adjust: none;
  scroll-behavior: smooth;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 700;
  line-height: 1.14;
  letter-spacing: -0.01em;
  font-family: "Unbounded", sans-serif;
}

.f-h1 {
  font-size: clamp(36px, 36px + 24 * (100vw - 768px) / 1152, 60px);
}

.f-h2 {
  font-size: clamp(28px, 28px + 16 * (100vw - 768px) / 1152, 44px);
}

.f-h3 {
  font-size: clamp(24px, 24px + 8 * (100vw - 768px) / 1152, 32px);
}

@font-face {
  font-family: "icomoon";
  src: url("../fonts/icomoon/fonts/icomoon.eot");
  src: url("../fonts/icomoon/fonts/icomoon.eot") format("embedded-opentype"), url("../fonts/icomoon/fonts/icomoon.ttf") format("truetype"), url("../fonts/icomoon/fonts/icomoon.woff") format("woff"), url("../fonts/icomoon/fonts/icomoon.svg") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
[class^=icon-], [class*=" icon-"], .icon {
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-ic-widjet:before {
  content: "\e94e";
}

.icon-ic-question:before {
  content: "\e94c";
}

.icon-ic-burger:before {
  content: "\e94d";
}

.icon-ic-arrow-down:before {
  content: "\e900";
}

.icon-ic-arrow-left:before {
  content: "\e901";
}

.icon-ic-arrow-right:before {
  content: "\e902";
}

.icon-ic-arrow-up:before {
  content: "\e903";
}

.icon-ic-arrow-up-right:before {
  content: "\e904";
}

.icon-ic-bell:before {
  content: "\e905";
}

.icon-ic-book:before {
  content: "\e906";
}

.icon-ic-bookmark:before {
  content: "\e907";
}

.icon-ic-book-open:before {
  content: "\e908";
}

.icon-ic-calendar:before {
  content: "\e909";
}

.icon-ic-check:before {
  content: "\e90a";
}

.icon-ic-chevron-down:before {
  content: "\e90b";
}

.icon-ic-chevron-left:before {
  content: "\e90c";
}

.icon-ic-chevron-right:before {
  content: "\e90d";
}

.icon-ic-chevrons-down:before {
  content: "\e90e";
}

.icon-ic-chevrons-left:before {
  content: "\e90f";
}

.icon-ic-chevrons-right:before {
  content: "\e910";
}

.icon-ic-chevrons-up:before {
  content: "\e911";
}

.icon-ic-chevron-up:before {
  content: "\e912";
}

.icon-ic-copy:before {
  content: "\e913";
}

.icon-ic-edit-2:before {
  content: "\e914";
}

.icon-ic-edit-3:before {
  content: "\e915";
}

.icon-ic-eye:before {
  content: "\e916";
}

.icon-ic-filter:before {
  content: "\e917";
}

.icon-ic-folder:before {
  content: "\e918";
}

.icon-ic-globe:before {
  content: "\e919";
}

.icon-ic-info:before {
  content: "\e91a";
}

.icon-ic-link-2:before {
  content: "\e91b";
}

.icon-ic-list:before {
  content: "\e91c";
}

.icon-ic-mail:before {
  content: "\e91d";
}

.icon-ic-map:before {
  content: "\e91e";
}

.icon-ic-map-pin:before {
  content: "\e91f";
}

.icon-ic-maximize:before {
  content: "\e920";
}

.icon-ic-maximize-2:before {
  content: "\e921";
}

.icon-ic-message-circle:before {
  content: "\e922";
}

.icon-ic-message-square:before {
  content: "\e923";
}

.icon-ic-minimize:before {
  content: "\e924";
}

.icon-ic-minus:before {
  content: "\e925";
}

.icon-ic-moon:before {
  content: "\e926";
}

.icon-ic-more-horizontal:before {
  content: "\e927";
}

.icon-ic-more-vertical:before {
  content: "\e928";
}

.icon-ic-paperclip:before {
  content: "\e929";
}

.icon-ic-pause:before {
  content: "\e92a";
}

.icon-ic-phone:before {
  content: "\e92b";
}

.icon-ic-play:before {
  content: "\e92c";
}

.icon-ic-plus:before {
  content: "\e92d";
}

.icon-ic-qr:before {
  content: "\e92e";
}

.icon-ic-quote:before {
  content: "\e92f";
}

.icon-ic-select-chevrones:before {
  content: "\e930";
}

.icon-ic-send:before {
  content: "\e931";
}

.icon-ic-settings:before {
  content: "\e932";
}

.icon-ic-shopping-cart:before {
  content: "\e933";
}

.icon-ic-sliders:before {
  content: "\e934";
}

.icon-ic-socials-dzen:before {
  content: "\e935";
}

.icon-ic-socials-facebook:before {
  content: "\e936";
}

.icon-ic-socials-instagram:before {
  content: "\e937";
}

.icon-ic-socials-odnoklassniki:before {
  content: "\e938";
}

.icon-ic-socials-telegram:before {
  content: "\e939";
}

.icon-ic-socials-viber:before {
  content: "\e93a";
}

.icon-ic-socials-vk:before {
  content: "\e93b";
}

.icon-ic-socials-whatsapp:before {
  content: "\e93c";
}

.icon-ic-socials-youtube:before {
  content: "\e93d";
}

.icon-ic-sun:before {
  content: "\e93e";
}

.icon-ic-tag:before {
  content: "\e93f";
}

.icon-ic-tiangle-chevron-down:before {
  content: "\e940";
}

.icon-ic-tiangle-chevron-left:before {
  content: "\e941";
}

.icon-ic-tiangle-chevron-right:before {
  content: "\e942";
}

.icon-ic-tiangle-chevron-up:before {
  content: "\e943";
}

.icon-ic-trash:before {
  content: "\e944";
}

.icon-ic-trash-2:before {
  content: "\e945";
}

.icon-ic-upload-folder:before {
  content: "\e946";
}

.icon-ic-user:before {
  content: "\e947";
}

.icon-ic-volume:before {
  content: "\e948";
}

.icon-ic-volume-x:before {
  content: "\e949";
}

.icon-ic-x:before {
  content: "\e94a";
}

.icon-ic-x-circle:before {
  content: "\e94b";
}

img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

a[href], .button {
  cursor: pointer;
  text-decoration-skip-ink: none;
  transition: 0.18s ease-out;
}

.disabled {
  opacity: 0.3;
  cursor: default;
  pointer-events: none;
}

.hidden {
  display: none;
}

.ux_col {
  padding: 180px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.ui_grid_buttons {
  max-width: 1440px;
  margin: 24px auto;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  place-items: center;
  gap: 24px;
  padding: 24px;
}
.ui_grid_buttons_title {
  grid-column: span 3;
  margin: 48px 24px 24px;
  color: var(--c-text-default);
}

body {
  max-width: 100vw;
  overflow-x: hidden;
  position: relative;
}

.page {
  min-height: 100vh;
}

main {
  z-index: 1;
  padding-top: 86px;
}
@media (max-width: 768px) {
  main {
    padding-top: 64px;
  }
}

section[id] {
  scroll-margin-top: calc(var(--headerHeight) + var(--g-section-inner-80));
}

@keyframes slidein {
  0% {
    opacity: 0;
    max-height: 0;
  }
  30% {
    opacity: 1;
  }
  100% {
    max-height: 200vh;
  }
}
/* Common styles for button */
.button {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  width: fit-content;
  padding: 10px 24px;
  line-height: 1.3;
  font-weight: 500;
  font-size: clamp(13px, 13px + 3 * (100vw - 768px) / 1152, 16px);
  border: 1px solid transparent;
  border-radius: var(--border-corner-button);
  cursor: pointer;
  z-index: 1;
  overflow: hidden;
  transition: var(--transition);
}
.button span {
  color: inherit;
  line-height: 1.3;
  text-align: center;
  position: relative;
  z-index: 2;
  font-weight: 400;
  transition: var(--transition);
  white-space: nowrap;
}
.button span[class^=icon-],
.button span[class*=" icon-"],
.button svg[class^=icon] {
  color: inherit;
  width: 1em;
  height: 1em;
  line-height: 1;
  font-size: 1.3em;
  aspect-ratio: 1/1;
  transition: var(--transition);
  display: flex;
}
.button span[class^=icon-]:only-child,
.button span[class*=" icon-"]:only-child,
.button svg[class^=icon]:only-child {
  margin: 0 -8px;
}

/* Button appearance */
.button.button-filled {
  border-radius: var(--border-corner-button);
  background: var(--filled-bgc-base);
  border-color: transparent;
  color: var(--filled-c-base);
}
.button.button-filled:hover, .button.button-filled:focus, .button.button-filled:active {
  background: var(--filled-bgc-hover);
  color: var(--filled-c-hover);
}

.button.button-outlined {
  border-radius: var(--border-corner);
  background-color: transparent;
  border-color: var(--outlined-bc-base);
  color: var(--outlined-c-base);
}
.button.button-outlined:hover, .button.button-outlined:focus, .button.button-outlined:active {
  background-color: var(--outlined-bgc-hover);
  border-color: var(--outlined-bc-hover);
  color: var(--outlined-c-hover);
}

.button.button-link {
  border: none;
  border-radius: 0;
  justify-content: flex-start;
  padding: 8px 0;
  color: var(--link-c-base);
  background: transparent;
  text-decoration: none;
}
.button.button-link span[class^=icon-]:only-child,
.button.button-link span[class*=" icon-"]:only-child,
.button.button-link svg[class^=icon]:only-child {
  margin: 0;
}
.button.button-link:visited {
  opacity: 0.8;
}
.button.button-link:hover, .button.button-link:focus, .button.button-link:active {
  color: var(--link-c-hover);
}

/* Button  colors  */
body .button.button-accent {
  --filled-bgc-base: var(--c-accent);
  --filled-c-base: var(--c-black);
  --filled-bgc-hover: var(--c-accent-hover);
  --filled-c-hover: var(--c-black);
  --outlined-bc-base: var(--c-accent);
  --outlined-c-base: var( --c-white);
  --outlined-bc-hover: var(--c-accent-hover);
  --outlined-c-hover: var( --c-white);
  --link-c-base: var( --c-white);
  --link-c-hover: var(--c-primary-hover);
}
body .button.button-primary {
  --filled-bgc-base: var(--c-primary);
  --filled-c-base: var(--c-black);
  --filled-bgc-hover: var(--c-primary-hover);
  --filled-c-hover: var(--c-black);
  --outlined-bc-base: var(--c-primary);
  --outlined-c-base: var( --c-white);
  --outlined-bgc-hover: var(--c-primary-hover);
  --outlined-bc-hover: var(--c-primary-hover);
  --outlined-c-hover: var( --c-white);
  --link-c-base: var(--c-primary);
  --link-c-hover: var(--c-primary-hover);
}
body .button.button-secondary {
  --filled-bgc-base: var(--c-secondary);
  --filled-c-base: var(--c-white);
  --filled-bgc-hover: var(--c-secondary-hover);
  --filled-c-hover: var(--c-white);
  --outlined-bc-base: var(--c-secondary);
  --outlined-c-base: var(--c-white);
  --outlined-bgc-hover: var(--c-secondary-hover);
  --outlined-bc-hover: var(--c-secondary-hover);
  --outlined-c-hover: var(--c-white);
  --link-c-base: var(--c-text-secondary);
  --link-c-hover: var(--c-white);
}

.swiper-slide,
.card,
.gallery--item,
.block_banner-group .banner {
  -webkit-transform: translate3d(0, 0, 0);
  -webkit-backface-visibility: hidden;
  flex-shrink: 0;
  flex-grow: 0;
}

.swiper-wrapper {
  -webkit-transform-style: preserve-3d;
}

.swiper-prev,
.swiper-next {
  border-radius: 100px;
  position: relative;
  line-height: 1;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: var(--fs-heading-md);
  flex-shrink: 0;
  flex-grow: 0;
  pointer-events: all;
}
.swiper-prev:after,
.swiper-next:after {
  font-family: "icomoon" !important;
  color: #fff;
  z-index: 2;
  position: relative;
}

.swiper-button-next,
.swiper-button-prev,
.swiper-prev,
.swiper-next {
  border-radius: var(--border-corner);
  background-color: var(--c-secondary);
  pointer-events: auto;
  line-height: 1;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  flex-grow: 0;
  width: 40px;
  height: 40px;
  position: static;
}
@media (min-width: 769px) {
  .swiper-button-next,
  .swiper-button-prev,
  .swiper-prev,
  .swiper-next {
    width: 44px;
    height: 44px;
  }
}
.swiper-button-next:after,
.swiper-button-prev:after,
.swiper-prev:after,
.swiper-next:after {
  font-family: "icomoon" !important;
  color: var(--c-text-default);
  font-size: 16px;
  z-index: 2;
}

.swiper-next:after,
.swiper-button-next:after {
  content: "\e942";
}

.swiper-next:before {
  right: -12px;
}

.swiper-prev:after,
.swiper-button-prev:after {
  content: "\e941";
}

.swiper-prev:before {
  left: -12px;
}

.swiper-button-disabled {
  cursor: auto;
  opacity: 0.3;
}

@media (max-width: 769px) {
  .slider_controls {
    display: none;
  }
}
.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  max-width: 100vw;
  z-index: 50;
  padding: 14px var(--container-padding);
  background-color: var(--c-base-bgc);
  border-bottom: 1px solid var(--c-secondary);
  border-bottom: 1px solid var(--c-secondary);
}
@media (min-width: 769px) {
  .header {
    padding: 20px var(--container-padding);
  }
}

.header--main {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 32px;
}

.header--logo {
  display: flex;
  align-items: center;
}
.header--logo svg {
  fill: var(--logo-color);
}

.header--nav {
  display: none;
}
@media (min-width: 769px) {
  .header--nav {
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.nav {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
}
.nav--item {
  position: relative;
  display: flex;
}
.nav--item_button {
  display: flex;
  align-items: center;
  font-size: var(--fs-body-sx);
  padding: 0px 10px;
  line-height: 1.33;
  gap: 8px;
  backdrop-filter: blur(20px);
  cursor: pointer;
  transition: var(--transition);
}
@media (max-width: 768px) {
  .nav--item_button {
    margin-bottom: 8px;
  }
}
.nav--item_button span {
  line-height: 1.33;
}

.nav--item {
  color: var(--c-text-secondary);
}
.nav--item [class^=icon-] {
  transition: var(--transition);
}
.nav--item.is_open {
  color: var(--c-white);
}
.nav--item.is_open [class^=icon-] {
  transform: scale(1, -1);
}

.header:not(.header-documentation) .nav--item:hover, .header:not(.header-documentation) .nav--item:focus, .header:not(.header-documentation) .nav--item:active, .header:not(.header-documentation) .nav--item:focus-within, .header:not(.header-documentation) .nav--item.is_open {
  color: var(--c-white);
  opacity: 1;
}
.header:not(.header-documentation) .nav--item:hover .nav--item_popover, .header:not(.header-documentation) .nav--item:focus .nav--item_popover, .header:not(.header-documentation) .nav--item:active .nav--item_popover, .header:not(.header-documentation) .nav--item:focus-within .nav--item_popover, .header:not(.header-documentation) .nav--item.is_open .nav--item_popover {
  display: flex;
  opacity: 1;
  pointer-events: all;
}
@media (max-width: 768px) {
  .header:not(.header-documentation) .nav--item:hover .nav--item_popover, .header:not(.header-documentation) .nav--item:focus .nav--item_popover, .header:not(.header-documentation) .nav--item:active .nav--item_popover, .header:not(.header-documentation) .nav--item:focus-within .nav--item_popover, .header:not(.header-documentation) .nav--item.is_open .nav--item_popover {
    animation: slidein 2s forwards;
  }
}
.header:not(.header-documentation) .nav--item:hover .nav--item_popover .nav--item_popover_content, .header:not(.header-documentation) .nav--item:focus .nav--item_popover .nav--item_popover_content, .header:not(.header-documentation) .nav--item:active .nav--item_popover .nav--item_popover_content, .header:not(.header-documentation) .nav--item:focus-within .nav--item_popover .nav--item_popover_content, .header:not(.header-documentation) .nav--item.is_open .nav--item_popover .nav--item_popover_content {
  opacity: 1;
  transform: translateY(0);
}
.header:not(.header-documentation) .nav--item:hover [class^=icon-], .header:not(.header-documentation) .nav--item:focus [class^=icon-], .header:not(.header-documentation) .nav--item:active [class^=icon-], .header:not(.header-documentation) .nav--item:focus-within [class^=icon-], .header:not(.header-documentation) .nav--item.is_open [class^=icon-] {
  transform: scale(1, -1);
}

.header.is_menu_opened {
  position: fixed;
}
.header.is_menu_opened .icon-ic-burger {
  display: none;
}
.header.is_menu_opened .icon-ic-x {
  display: flex;
}

.header:not(.header-documentation) .nav--item_popover {
  display: flex;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  top: 100%;
  left: 50%;
  padding: 20px 0;
  transform: translateX(-50%);
}
.header:not(.header-documentation) .nav--item_popover_content {
  min-width: 232px;
  background: #2A292A;
  border: 1px solid rgba(255, 255, 255, 0.1019607843);
  border-radius: var(--border-corner);
  opacity: 0;
  transform: translateY(8px);
  transition: var(--transition);
}

.nav--list {
  padding: 4px;
  list-style: none;
}
.nav--list_item {
  padding: 8px 12px;
  display: flex;
  gap: 12px;
  align-items: center;
  justify-content: flex-start;
  border-radius: 8px;
  transition: var(--transition);
  font-size: var(--fs-body-sx);
}
.nav--list_item:hover, .nav--list_item:active, .nav--list_item:focus {
  background: var(--c-secondary);
}

.header--actions {
  display: flex;
  position: relative;
  gap: 16px;
}
@media (max-width: 768px) {
  .header--actions .button:not([data-openpopup]) {
    padding: 7px 16px;
  }
  .header--actions .button:not([data-openpopup]) span {
    line-height: 1.1em;
  }
}
.header--actions [class^=icon-] {
  transition: var(--transition);
}
.header--actions .icon-ic-x {
  display: none;
}

.menu_button {
  font-size: 24px;
  padding: 0;
}
@media (min-width: 769px) {
  .menu_button {
    display: none;
  }
}

.header-simple .header--main {
  justify-content: center;
}

@media (min-width: 999px) {
  .header-documentation {
    position: static;
    height: 100%;
    padding: var(--g-section-inner-32) var(--g-section-inner-24);
    background-color: var(--c-base-bgc);
  }
}
@media (min-width: 999px) {
  .header-documentation .header--main {
    top: var(--g-section-inner-32);
    position: sticky;
    align-items: flex-start;
    flex-direction: column;
  }
}
@media (max-width: 998px) {
  .header-documentation .menu_button {
    display: flex;
  }
}

.nav-documentation.nav {
  display: none;
}
@media (min-width: 999px) {
  .nav-documentation.nav {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .nav-documentation.nav .nav--item {
    display: flex;
    flex-direction: column;
    color: var(--c-text-secondary);
  }
  .nav-documentation.nav .nav--item.is_open .nav--item_button {
    color: #FFFFFF;
  }
  .nav-documentation.nav .nav--item.is_open .nav--item_popover {
    animation: slidein 2s forwards;
  }
  .nav-documentation.nav .nav--item_button {
    padding: 5px 8px;
    font-size: 13px;
  }
}
@media (min-width: 999px) and (min-width: 769px) {
  .nav-documentation.nav .nav--item_button {
    font-size: 15px;
  }
}
@media (min-width: 999px) {
  .nav-documentation.nav .nav--item_popover {
    max-height: 0;
    overflow: hidden;
    pointer-events: none;
    font-size: 13px;
    padding-left: 14px;
  }
}
@media (min-width: 999px) and (min-width: 769px) {
  .nav-documentation.nav .nav--item_popover {
    font-size: 15px;
  }
}
@media (min-width: 999px) {
  .nav-documentation.nav .nav--list {
    padding: 6px 0;
  }
  .nav-documentation.nav .nav--list_item {
    color: var(--c-text-secondary);
    border-radius: 0;
    border-left: 1px solid var(--c-text-secondary);
  }
  .nav-documentation.nav .nav--list_item.is_current {
    color: #fff;
    border-color: #fff;
  }
}

.breadcrumbs {
  padding: var(--g-section-inner) var(--container-padding);
  display: flex;
  align-items: center;
  z-index: 5;
  width: 100%;
  flex-wrap: nowrap;
  max-width: 100vw;
  overflow-x: auto;
}
.breadcrumbs a {
  transition: var(--transition);
  position: relative;
  color: var(--c-text-default);
  white-space: nowrap;
  padding: 0 16px;
  font-size: var(--fs-body-sx);
}
.breadcrumbs a:not(:last-child):after {
  content: ">";
  position: absolute;
  right: 0;
  translate: 50% 0;
}
.breadcrumbs a:first-child {
  padding-left: 0;
}
.breadcrumbs a:last-child {
  pointer-events: none;
  color: var(--c-text-disabled);
}
.breadcrumbs a:hover, .breadcrumbs a:focus, .breadcrumbs a:active {
  color: var(--c-text-default);
}

.header-transparent ~ main > .breadcrumbs {
  position: absolute;
  top: var(--headerHeight);
  padding: var(--g-section-inner) calc(var(--container-padding) + (var(--column) + var(--gap)) * 2);
  left: 0;
}

.accordion {
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: var(--transition);
  border-bottom: 1px solid var(--c-secondary);
}
@media (max-width: 768px) {
  .accordion {
    padding: 4px;
  }
}
.accordion.is_open .icon {
  color: #fff;
  transform: rotate(90deg);
  transform-origin: center;
}
.accordion--button {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  position: relative;
  padding: var(--g-section-inner-24) var(--g-section-inner-24) var(--g-section-inner-24) var(--g-section-inner-32);
  color: var(--c-text-default);
}
.accordion--button .accordion--button_text {
  margin: 0;
}
.accordion--button .icon {
  position: absolute;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  flex-grow: 0;
  height: 1.33em;
  width: 1.33em;
  transform: rotate(0deg);
  transition: var(--transition);
  color: rgba(255, 255, 255, 0.4);
}
.accordion--button .icon:before {
  content: "\e90d";
}
.accordion--content {
  padding: 0 var(--g-section-inner-24);
  color: var(--c-text-subdued);
}
.accordion--content.is_cascade > * {
  color: var(--c-primary);
}
.accordion--content.is_cascade > *:last-child {
  padding-bottom: var(--g-section-inner-24);
}
.accordion--content.is_cascade > p:last-child {
  margin: 0;
}

.popup {
  padding: var(--g-section-inner-32) var(--g-section-inner-16);
}
@media (max-width: 768px) {
  .popup {
    padding: var(--g-section-inner-16);
    place-items: start;
    height: -webkit-fill-available;
    height: calc(100 * var(--vh, 1vh));
  }
}
.popup--content {
  width: 100%;
  max-width: 780px;
  background-color: #121112;
  border-radius: var(--border-corner-card);
  border: 1px solid var(--c-secondary);
  padding: var(--g-section-inner-32);
}
.popup--close {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 8px;
  right: 8px;
  z-index: 5;
  cursor: pointer;
  transition: 0.3s ease-out;
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 42px;
  height: 42px;
  border-radius: var(--border-corner);
  font-size: 25px;
}
.popup--close:after {
  content: "\e94a";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
@media (max-width: 768px) {
  .popup--close {
    left: unset;
    right: 16px;
    top: 16px;
  }
}

.popup.popup--menu {
  place-items: end;
  padding: 0;
}
.popup.popup--menu .popup--close {
  width: 32px;
  height: 32px;
}
.popup.popup--menu .popup--content {
  overflow: auto;
  width: clamp(325px, 25vw, 360px);
  opacity: 0;
  transition: 0.18s ease-out;
  height: calc(100vh - var(--headerHeight));
  border-radius: 0;
}
@media (max-width: 768px) {
  .popup.popup--menu .popup--content {
    width: 100%;
  }
}
.popup.popup--menu .nav {
  display: flex;
  max-width: 312px;
  margin: auto;
  flex-direction: column;
  align-items: flex-start;
}
.popup.popup--menu .nav--item {
  overflow: hidden;
  flex-direction: column;
}
.popup.popup--menu .nav--item_popover {
  max-height: 0;
  transition: max-height 0.3s ease;
}
.popup.popup--menu .nav--item_button {
  padding: 0;
}
.popup.popup--menu .nav--item.is_open .nav--item_popover {
  animation: slidein 2s forwards;
}
.popup.popup--menu .nav--item.is_open [class^=icon-] {
  transform: scale(1, -1);
}
.popup.popup--menu .nav--list {
  padding: 0;
}
.popup.popup--menu .nav--list_item {
  padding: 8px;
}
.popup.popup--menu.is_active .popup--content {
  opacity: 1;
}

.popup.popup--form,
.popup.popup-cascade {
  background-color: rgba(0, 0, 0, 0.35);
  backdrop-filter: blur(5px);
}

.popup[data-popup=popup_for_notification] {
  justify-items: center;
  align-items: end;
}
.popup[data-popup=popup_for_notification] .popup--content {
  max-width: 512px;
  padding: 12px;
  background-color: #2A292A;
  display: flex;
  gap: 16px;
  justify-content: space-between;
  align-items: center;
}
.popup[data-popup=popup_for_notification] .popup--content-title {
  font-size: 16px;
  font-weight: 400;
}

.popup[data-popup=popup_contents] .popup--content {
  width: 100vw;
}

.js-gallerySwiper {
  display: flex;
  width: 100%;
  position: relative;
  --c-text-default: #fff;
}
.js-gallerySwiper .gallery--item {
  border-radius: var(--border-corner-card);
  overflow: hidden;
  aspect-ratio: 640/400;
}
@media (max-width: 768px) {
  .js-gallerySwiper .gallery--item {
    aspect-ratio: 328/218;
  }
}
.js-gallerySwiper .slider_controls {
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: 50%;
  left: 50%;
  translate: -50% -50%;
  width: calc(100% - var(--g-section-inner-32));
  align-items: center;
  color: #fff;
  z-index: 5;
  pointer-events: none;
}
.js-gallerySwiper .slider_controls .swiper-next {
  background-color: var(--c-primary);
  border-radius: var(--border-corner);
  padding: 10px;
}
.js-gallerySwiper .slider_controls .swiper-next:after {
  content: "\e90d";
  color: var(--c-black);
}
.js-gallerySwiper .slider_controls .swiper-prev {
  background-color: var(--c-primary);
  border-radius: var(--border-corner);
  padding: 10px;
}
.js-gallerySwiper .slider_controls .swiper-prev:after {
  content: "\e90c";
  color: var(--c-black);
}

.block_element.content_article,
.block_element.content_blog {
  padding-top: 0;
}
.block_element.content_article .block--article_head,
.block_element.content_blog .block--article_head {
  margin: var(--g-section-inner) auto 0;
}
.block_element.content_article .block--article_head_title,
.block_element.content_blog .block--article_head_title {
  font-size: var(--fs-heading-xl);
  margin-bottom: 12px;
}
.block_element.content_article .block--article_head_subtitle,
.block_element.content_blog .block--article_head_subtitle {
  font-size: var(--fs-body-sx);
  color: var(--c-text-secondary);
}
.block_element.content_article article.is_cascade,
.block_element.content_article .block--article_head,
.block_element.content_blog article.is_cascade,
.block_element.content_blog .block--article_head {
  max-width: 640px;
  width: 100%;
}
.block_element.content_article article,
.block_element.content_blog article {
  margin: var(--g-section-inner-48) auto 0;
}
.block_element.content_article article .block--head,
.block_element.content_blog article .block--head {
  max-width: 100%;
}

.is_cascade {
  --margin-xl: clamp(48px, calc(48px + (56 - 48) * ((100vw - 768px) / (1920 - 768))), 56px);
  --margin-l: clamp(24px, calc(24px + (36 - 24) * ((100vw - 768px) / (1920 - 768))), 36px);
  --margin-m: clamp(20px, calc(20px + (24 - 20) * ((100vw - 768px) / (1920 - 768))), 24px);
  font-size: var(--fs-body-sm);
  color: var(--c-primary);
}
.is_cascade > h1, .is_cascade > h2, .is_cascade > h3, .is_cascade > h4, .is_cascade > h5, .is_cascade > h6 {
  margin: var(--g-elements) 0;
  font-weight: 700;
  color: var(--c-text-default);
  scroll-margin-top: 100px;
  line-height: 1.1;
}
.is_cascade > h1 {
  margin: var(--margin-xl) 0 var(--margin-l);
  font-size: var(--fs-heading-xxl);
}
.is_cascade > h1:first-child {
  margin-top: 0;
}
.is_cascade > h2 {
  margin: var(--margin-xl) 0 var(--margin-m);
  font-size: var(--fs-heading-xl);
}
.is_cascade > h3 {
  margin: var(--margin-xl) 0 var(--margin-m);
  font-size: var(--fs-heading-lg);
}
.is_cascade > h4 {
  margin: var(--margin-l) 0 var(--margin-m);
  font-size: var(--fs-heading-md);
}
.is_cascade > h5, .is_cascade h6 {
  margin: var(--margin-l) 0 var(--margin-m);
  font-size: var(--fs-body-md);
}
.is_cascade p {
  margin-bottom: var(--g-elements);
}
.is_cascade p > strong, .is_cascade p > b {
  font-weight: 700;
}
.is_cascade a:not(.button) {
  text-decoration: underline;
}
.is_cascade a:not(.button):hover, .is_cascade a:not(.button):active {
  color: var(--c-text-accent-hovered);
}
.is_cascade a:not(.button):visited {
  opacity: 0.8;
}
.is_cascade > strong {
  font-weight: 400;
}
.is_cascade ul, .is_cascade ol {
  margin: var(--g-elements) 0;
  padding-left: 20px;
}
.is_cascade ul li:not(:last-of-type), .is_cascade ol li:not(:last-of-type) {
  margin-bottom: 8px;
}
.is_cascade figure {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin: var(--g-elements) 0;
}
.is_cascade figure img, .is_cascade figure video {
  width: 100%;
  object-fit: cover;
}
.is_cascade figure figcaption {
  color: var(--c-text-disabled);
  text-align: center;
  font-size: 16px;
  margin-top: 16px;
}
.is_cascade video, .is_cascade img, .is_cascade iframe, .is_cascade object {
  object-fit: contain;
  margin: 0 auto;
  object-position: center;
  width: 100%;
}
.is_cascade > img, .is_cascade > video, .is_cascade > iframe, .is_cascade > object {
  margin: var(--g-elements) 0;
  height: auto;
}
.is_cascade .is_cascade--slider {
  margin: var(--g-elements) 0;
  overflow: hidden;
}
.is_cascade .is_cascade--slider figure {
  height: 100%;
  margin: 0;
}
.is_cascade blockquote {
  margin: var(--g-elements) 0;
  padding-left: var(--g-section-inner);
  border-left: 2px solid var(--c-text-default);
  display: flex;
  flex-direction: column;
  gap: var(--g-elements);
}
.is_cascade blockquote .icon-ic-quote {
  font-size: 24px;
}
.is_cascade blockquote p {
  line-height: 1.5;
  margin: 0;
}
.is_cascade blockquote .blockquote--author {
  display: grid;
  grid-template-columns: 48px 1fr;
  gap: 0 16px;
}
.is_cascade blockquote .blockquote--author img {
  place-self: center;
  grid-column: 1;
  grid-row: span 2;
  width: 48px;
  height: 48px;
  object-fit: cover;
  object-position: center;
  border-radius: 50% !important;
  overflow: hidden;
}
.is_cascade blockquote .blockquote--author p {
  margin: 0;
  grid-column: 2;
  font-size: var(--fs-body-md);
}
.is_cascade blockquote .blockquote--author span {
  grid-column: 2;
  font-size: 16px;
  color: var(--c-text-subdued);
}
.is_cascade .button_wrapper, .is_cascade .block--actions {
  display: flex;
  align-items: center;
  gap: 8px;
  margin: var(--g-elements) 0;
}
.is_cascade .is_cascade--accordion {
  margin: var(--g-elements) 0;
}
.is_cascade--table {
  max-width: 100vw;
  overflow-x: auto;
  margin: var(--g-elements) 0;
}
.is_cascade table {
  border-collapse: collapse;
  width: 100%;
  border: 1px solid var(--c-secondary-hover);
  text-align: left;
}
.is_cascade table thead {
  background-color: var(--c-base-bgc);
}
.is_cascade table thead,
.is_cascade table tbody th {
  position: sticky;
  font-weight: 700;
  text-align: left;
}
.is_cascade table tbody th:only-of-type {
  left: 0;
}
.is_cascade table tbody th:not(:only-of-type) {
  top: 0;
}
.is_cascade table thead {
  top: 0;
}
.is_cascade table th {
  max-width: 30%;
  background-clip: padding-box;
  background-color: var(--c-secondary);
  box-shadow: 6px 0px 13px 0px rgba(0, 0, 0, 0.02), 23px 0px 23px 0px rgba(0, 0, 0, 0.02), 53px 0px 32px 0px rgba(0, 0, 0, 0.01), 93px 0px 37px 0px rgba(0, 0, 0, 0), 146px 0px 41px 0px rgba(0, 0, 0, 0);
}
.is_cascade table td, .is_cascade table th {
  border: 1px solid var(--c-secondary-hover);
  padding: 8px 16px;
}

select {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  display: none;
}
select option {
  display: none;
}

.select {
  position: relative;
}
.select:hover .select--button {
  border-color: var(--c-input-border-hover);
}
.select:has(.is_selected) .select--button {
  border-color: var(--c-input-border-hover);
  color: var(--c-text-default);
}
.select--button {
  border: 1px solid var(--c-input-border);
  background-color: var(--c-input-bg);
  border-radius: 8px;
  padding: 12px 16px;
  position: relative;
  font-size: 16px;
  cursor: pointer;
  transition: var(--transition);
  color: var(--c-text-disabled);
}
.select--button:hover, .select--button:focus {
  border-color: var(--c-input-border-hover);
}
.select--button:after {
  font-family: "icomoon" !important;
  content: "\e930";
  display: grid;
  place-items: center;
  width: 24px;
  height: 100%;
  position: absolute;
  right: 10px;
  top: 0;
}
.select--option_wrapper {
  opacity: 0;
  pointer-events: none;
  padding-top: 12px;
  position: absolute;
  width: 100%;
  height: fit-content;
  max-height: 320px;
  z-index: 3;
}
.select--option_wrapper.is_opened {
  opacity: 1;
  pointer-events: all;
}
.select--option_wrapper.is_opened .select--option_list {
  transform: translateY(0);
}
.select--option_list {
  background-color: var(--c-base-bgc);
  transition: var(--transition);
  transform: translateY(-8px);
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 9px;
  border: 1px solid var(--c-input-border);
  border-radius: 8px;
  box-shadow: var(--box-shadow);
  color: var(--c-text-default);
}
.select--option {
  padding: 8px 16px;
  transition: var(--transition);
  cursor: pointer;
  border-radius: 8px;
  font-size: 16px;
  display: flex;
  align-items: center;
  color: var(--c-text-default);
}
.select--option:hover, .select--option:active, .select--option:focus {
  background-color: var(--c-secondary);
}
.select--option.is_selected {
  background-color: rgb(202, 250, 101);
}
.select--option.is_selected:hover, .select--option.is_selected:active, .select--option.is_selected:focus {
  background-color: rgb(202, 250, 101);
}

.form--input_file {
  border: 1px solid var(--c-input-border);
  background-color: var(--c-input-bg);
  padding: 12px 16px;
  border-radius: 8px;
  cursor: pointer;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  gap: 12px;
  color: var(--c-text-disabled);
}
.form--input_file:hover, .form--input_file:focus, .form--input_file:focus-within {
  border-color: var(--c-input-border-hover);
}
.form--input_file .icon-ic-paperclip {
  line-height: 1.5;
}
.form--input_file p {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  margin: 0;
}
.form--input_file input {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  opacity: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.form--input_file--items {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin: 12px 0;
}
.form--input_file--items:empty {
  display: none;
}

.form--input_file--items_loaded {
  padding: 12px 44px 12px 60px;
  border: 1px solid var(--c-input-border);
  border-radius: 8px;
  position: relative;
  font-size: 16px;
}
.form--input_file--items_loaded:before {
  content: "\e927";
  font-family: "icomoon" !important;
  position: absolute;
  left: 0;
  top: 0;
  width: 60px;
  height: 100%;
  animation: loading 1.6s infinite linear;
  display: flex;
  align-items: center;
  justify-content: center;
}
.form--input_file--items_loaded > p, .form--input_file--items_loaded span {
  margin: 0;
}
.form--input_file--items_loaded.loaded:before {
  animation: none;
  content: "\e918";
}
.form--input_file--items_loaded.loaded.error:before {
  content: "\e91a";
  color: var(--error-color);
}

@keyframes loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.form--input_file--items_delete {
  content: "";
  width: 42px;
  height: 42px;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  display: grid;
  place-items: center;
  cursor: pointer;
  color: var(--c-text-disabled);
  transition: var(--transition);
}
.form--input_file--items_delete:hover {
  color: var(--c-text-default);
}

.form--input_file--items_error {
  padding-top: 8px;
  font-size: 12px;
  color: var(--error-color);
}

.form--input_date {
  font-size: var(--fs-md);
  border-radius: var(--c-input-bg);
  border: 1px solid transparent;
  background-color: var(--neutral-container-soft);
  transition: var(--transition);
  width: 100%;
  position: relative;
}
.form--input_date:after {
  content: "\e909";
  font-family: "icomoon";
  margin-left: auto;
  font-size: var(--fs-md);
  position: absolute;
  top: 12px;
  right: 16px;
  color: var(--c-text-disabled);
}
.form--input_date input {
  padding: 12px 16px;
  line-height: 1.5;
  font-size: 16px;
  border-radius: 8px;
  border: 1px solid var(--c-input-border);
  background-color: var(--c-input-bg);
  transition: var(--transition);
  width: 100%;
}
.form--input_date input::placeholder, .form--input_date input:placeholder-shown {
  color: var(--c-text-disabled);
}
.form--input_date input:not(:placeholder-shown) {
  color: var(--c-text-default);
}
.form--input_date input:hover, .form--input_date input:focus, .form--input_date input:focus-within {
  border-color: var(--c-input-border-hover);
}

.air-datepicker {
  color: var(--c-text-default);
  --adp-font-family: inherit;
  --adp-font-size: 16px;
  --adp-width: 328px;
  --adp-z-index: 100;
  --adp-padding: 0;
  --adp-grid-areas: "nav" "body" "timepicker" "buttons";
  --adp-transition-duration: .3s;
  --adp-transition-ease: ease-out;
  --adp-transition-offset: 8px;
  --adp-background-color: #1E1D1E;
  --adp-background-color-hover: transparent;
  --adp-background-color-active: transparent;
  --adp-background-color-in-range: var(--neutral-container-soft);
  --adp-background-color-in-range-focused: var(--neutral-container-soft);
  --adp-background-color-selected-other-month-focused: var(--c-text-default);
  --adp-background-color-selected-other-month: var(--c-text-default);
  --adp-color: #4a4a4a;
  --adp-color-secondary: #9c9c9c;
  --adp-accent-color: var(--c-text-default);
  --adp-color-current-date: var(--accent-default);
  --adp-color-other-month: var(--c-text-default);
  --adp-color-disabled: var(--fg-disabled);
  --adp-color-disabled-in-range: #939393;
  --adp-color-other-month-hover: #c5c5c5;
  --adp-border-color: transparent;
  --adp-border-color-inner: transparent;
  --adp-border-radius: 4px;
  --adp-border-color-inline: var(--c-secondary);
  --adp-nav-height: 32px;
  --adp-nav-arrow-color: var(--c-text-default);
  --adp-nav-action-size: 32px;
  --adp-nav-color-secondary: var(--c-text-default);
  --adp-day-name-color: var(--c-text-disabled);
  --adp-day-name-color-hover: var(--c-text-default);
  --adp-day-cell-width: 1fr;
  --adp-day-cell-height: 36px;
  --adp-month-cell-height: 42px;
  --adp-year-cell-height: 56px;
  --adp-pointer-size: 10px;
  --adp-poiner-border-radius: 2px;
  --adp-pointer-offset: 14px;
  --adp-cell-border-radius: 8px;
  --adp-cell-background-color-hover: var(--adp-background-color-hover);
  --adp-cell-background-color-selected: var(--c-secondary);
  --adp-cell-background-color-selected-hover: var(--c-secondary-hover);
  --adp-cell-border-color-in-range: var(--c-text-default);
  --adp-btn-height: 48px;
  --adp-time-track-height: 1px;
  --adp-time-track-color: #dedede;
  --adp-time-track-color-hover: #b1b1b1;
  --adp-time-thumb-size: 12px;
  --adp-time-padding-inner: 10px;
  --adp-time-day-period-color: var(--adp-color-secondary);
  padding: 16px;
  border-radius: 16px;
  -webkit-box-shadow: 0 4px 6px -2px rgba(26, 26, 26, 0.2), 0 1px 0 0 rgba(204, 204, 204, 0.5) inset, 0 -1px 0 0 rgba(0, 0, 0, 0.17) inset, -1px 0 0 0 rgba(0, 0, 0, 0.13) inset, 1px 0 0 0 rgba(0, 0, 0, 0.13) inset;
  box-shadow: 0 4px 6px -2px rgba(26, 26, 26, 0.2), 0 1px 0 0 rgba(204, 204, 204, 0.5) inset, 0 -1px 0 0 rgba(0, 0, 0, 0.17) inset, -1px 0 0 0 rgba(0, 0, 0, 0.13) inset, 1px 0 0 0 rgba(0, 0, 0, 0.13) inset;
}

@media (max-width: 992px) {
  .air-datepicker {
    padding: 8px;
  }
}
.air-datepicker-cell:not(.-disabled-):hover {
  background: var(--c-secondary-hover);
}

.air-datepicker-button.airpicker_button_apply {
  min-width: 100%;
  color: var(--filled-c-base, #fff);
  border-radius: var(--border-corner);
  background: var(--filled-bgc-base, #100a0a);
}

.air-datepicker--buttons {
  padding-top: 16px;
}

.air-datepicker-body--day-names {
  padding: 8px 0;
}

.air-datepicker-body--day-name {
  font-size: 14px;
}

.-disabled-.-focus-.air-datepicker-cell,
.air-datepicker-cell.-day-.-other-month-,
.air-datepicker-cell.-year-.-other-decade- {
  color: var(--c-text-disabled);
}

.air-datepicker-cell.-disabled- {
  color: var(--c-text-disabled) !important;
}

.air-datepicker-cell.-in-range- {
  background: var(--c-secondary);
}

input, textarea, select {
  border: none;
  outline: none;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

textarea {
  resize: vertical;
}

form {
  --c-input-bg: var(--c-base-bgc);
  --c-input-border: rgba(255,255,255, 0.10);
  --input-check-rest: rgba(255,255,255, 0.35);
  --c-input-border-hover: rgba(255,255,255, 0.4);
  --input-check-hover: var(--c-white);
  --input-check-color: var(--c-black);
  --error-color: --c-critical;
  max-width: 868px;
}
form fieldset {
  padding: 0;
  border-color: transparent;
  display: flex;
  flex-direction: column;
  gap: var(--g-section-inner-32);
}
form .form--title {
  font-size: var(--fs-heading-md);
  font-weight: 500;
  letter-spacing: -0.02em;
  margin-bottom: var(--g-section-inner-16);
}
form .form--text {
  margin-bottom: var(--g-section-inner-16);
  font-size: var(--fs-body-sx);
}
form legend {
  color: var(--c-text-default);
  font-size: var(--fs-heading-lg);
  font-weight: 700;
  line-height: 1.14;
  letter-spacing: -0.02em;
  font-family: "Unbounded", sans-serif;
  margin-bottom: var(--g-section-inner-32);
}
form .form--input {
  display: flex;
  flex-direction: column;
}
form .form--input > span {
  order: -1;
  font-size: var(--fs-body-md);
  font-weight: 500;
  transition: var(--transition);
  margin-bottom: 12px;
  color: var(--c-text-default);
}
form .form--input > input, form .form--input textarea {
  padding: 12px 16px;
  line-height: 1.5;
  font-size: 16px;
  border-radius: var(--border-corner);
  border: 1px solid var(--c-input-border);
  background-color: var(--c-base-bgc);
  transition: var(--transition);
  width: 100%;
}
form .form--input > input::placeholder, form .form--input > input:placeholder-shown, form .form--input textarea::placeholder, form .form--input textarea:placeholder-shown {
  color: var(--c-text-disabled);
}
form .form--input > input:not(:placeholder-shown), form .form--input textarea:not(:placeholder-shown) {
  color: var(--c-text-default);
}
form .form--input > input:hover, form .form--input > input:focus, form .form--input > input:focus-within, form .form--input textarea:hover, form .form--input textarea:focus, form .form--input textarea:focus-within {
  border-color: var(--c-input-border-hover);
}
form .form--input > input:invalid:not(:placeholder-shown), form .form--input textarea:invalid:not(:placeholder-shown) {
  border-color: var(--c-critical);
}
form .form--input > input:valid:not(:placeholder-shown), form .form--input textarea:valid:not(:placeholder-shown) {
  border-color: var(--c-input-border-hover);
}
form .form--input > input:valid:not(:placeholder-shown):not(:focus), form .form--input textarea:valid:not(:placeholder-shown):not(:focus) {
  border-color: var(--c-input-border);
}
form .form--input > input:required ~ span:after, form .form--input textarea:required ~ span:after {
  content: "*";
  color: var(--c-accent);
  margin-left: 5px;
}
form .form--input.error .form--input_error {
  opacity: 1;
  max-height: fit-content;
  height: auto;
  color: var(--error-color);
  padding-top: 12px;
}
form .form--input_error {
  color: var(--c-critical);
  opacity: 0;
  font-size: 16px;
  line-height: 24px;
  max-height: 0;
  height: 0;
  overflow: hidden;
  transition: opacity 0.03s 0.15s ease-out, max-height 0.18s linear;
}
form .form--input > label {
  display: flex;
  align-items: flex-start;
}
form .form--input > label + label {
  margin-top: 16px;
}
form input[type=radio],
form input[type=checkbox] {
  display: none;
  order: -1;
}
form input[type=radio] ~ span,
form input[type=checkbox] ~ span {
  order: 1;
  font-size: clamp(16px, 16px + 2 * (100vw - 768px) / 1152, 18px);
  line-height: 24px;
  margin-left: 12px;
  color: var(--c-text-default);
}
form input[type=radio] ~ .radio, form input[type=radio] ~ .checkbox, form input[type=radio] ~ .slider,
form input[type=checkbox] ~ .radio,
form input[type=checkbox] ~ .checkbox,
form input[type=checkbox] ~ .slider {
  flex-shrink: 0;
  flex-grow: 0;
  cursor: pointer;
}
form input[type=checkbox] ~ .checkbox {
  transition: var(--transition);
  width: 24px;
  height: 24px;
  border-radius: 6px;
  border: 2px solid var(--input-check-rest);
  background-color: transparent;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
form input[type=checkbox] ~ .checkbox:after {
  content: "\e90a";
  font-family: "icomoon" !important;
  color: var(--input-check-color);
  transition: var(--transition);
  opacity: 0;
  font-size: 16px;
}
form input[type=checkbox] ~ .checkbox:hover, form input[type=checkbox] ~ .checkbox:focus {
  border-color: var(--input-check-hover);
  background-color: transparent;
}
form input[type=checkbox] ~ .slider {
  width: 46px;
  height: 22px;
  outline: 1px solid var(--input-check-rest);
  border-radius: 1000px;
  position: relative;
  transition: var(--transition);
  background-color: rgba(255, 255, 255, 0.4);
}
form input[type=checkbox] ~ .slider:after {
  content: "";
  width: 20px;
  height: 20px;
  position: absolute;
  left: 2px;
  top: 1px;
  border-radius: 50%;
  background-color: var(--c-black);
  transition: var(--transition);
  z-index: 2;
  flex-shrink: 0;
  flex-grow: 0;
}
form input[type=checkbox]:checked ~ .checkbox {
  background-color: var(--input-check-hover);
  border-color: var(--input-check-hover);
}
form input[type=checkbox]:checked ~ .checkbox:after {
  opacity: 1;
}
form input[type=checkbox]:checked ~ .slider {
  background-color: var(--c-accent);
  outline-color: var(--c-accent);
}
form input[type=checkbox]:checked ~ .slider:after {
  transform: translateX(22px);
  background-color: var(--c-black);
}
form input[type=radio] ~ .radio {
  transition: var(--transition);
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 2px solid var(--input-check-rest);
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
form input[type=radio] ~ .radio:after {
  content: "\e90a";
  font-family: "icomoon" !important;
  color: var(--input-check-color);
  opacity: 0;
  transition: var(--transition);
  font-size: 16px;
}
form input[type=radio] ~ .radio:hover, form input[type=radio] ~ .radio:focus {
  border-color: var(--input-check-hover);
  background-color: transparent;
}
form input[type=radio]:checked ~ .radio {
  background-color: var(--input-check-hover);
  border-color: var(--input-check-hover);
}
form input[type=radio]:checked ~ .radio:after {
  opacity: 1;
}
form input[type=date] {
  appearance: none;
}

.form--policy {
  font-size: 16px;
  font-weight: 400;
  color: var(--c-text-disabled);
}
.form--policy a:not(.button) {
  font-size: 16px;
  font-weight: 400;
  color: var(--c-text-default);
}

.form.is_sending button[type=submit] {
  background: var(--c-secondary-hover);
  pointer-events: none;
}

.menu {
  overflow: scroll;
  padding: 0 32px 12px;
  height: 100%;
}
.menu--nav {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 32px 8px;
}
.menu--nav--item_list {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.menu--nav--item .button {
  padding: 0;
  font-size: 16px;
}
.menu--nav--item-solutions {
  grid-column: span 2;
  display: flex;
  flex-direction: column;
  gap: 16px;
  color: #FFFFFF;
}
.menu--nav--item-solutions__image {
  aspect-ratio: 1/1;
  max-width: 25%;
  display: flex;
  border-radius: 6px;
  overflow: hidden;
}
.menu--nav--item-solutions a {
  gap: 16px;
  padding: 4px;
  display: flex;
  align-items: center;
  background-color: #121111;
  border-radius: 6px;
  position: relative;
}
.menu--nav--item-solutions a p {
  font-weight: 700;
}
.menu--nav--item-solutions a span {
  font-size: 14px;
}
.menu--nav--item-solutions a:before {
  font-family: "icomoon" !important;
  content: "\e91b";
  width: 16px;
  height: 16px;
  position: absolute;
  top: 9px;
  left: auto;
  right: 8px;
  color: #FFFFFF;
}
.menu--nav--item_title {
  color: #8C8989;
  font-size: 14px;
  margin-bottom: 12px;
}

.footer {
  padding: 40px var(--container-padding);
  position: relative;
  display: flex;
  flex-direction: column;
  gap: var(--g-vertical);
  color: var(--c-text-disabled);
  z-index: 2;
  border-top: 1px solid var(--c-secondary);
}
@media (min-width: 768px) {
  .footer {
    padding: var(--g-section-inner-64) var(--container-padding);
  }
}
.footer--main {
  display: flex;
  flex-direction: column-reverse;
  gap: 40px;
}
@media (min-width: 768px) {
  .footer--main {
    display: grid;
    gap: var(--g-section-inner-32, 32px);
    grid-template-columns: auto 1fr;
  }
}
.footer--content {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.footer--main_title {
  color: var(--c-white, #fff);
  margin-bottom: 12px;
  font-size: var(--fs-body-sm);
  font-weight: 700;
}

.footer--nav {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}
@media (max-width: 992px) {
  .footer--nav {
    gap: 12px;
  }
}
@media (min-width: 768px) {
  .footer--nav {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (min-width: 1240px) {
  .footer--nav {
    grid-template-columns: repeat(4, 1fr);
  }
}

.footer--nav_col_list {
  grid-area: column;
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.footer--nav_col_list .button {
  font-size: var(--fs-body-sx);
  padding: 0;
}

.copyright {
  color: var(--c-text-disabled);
  font-size: clamp(11px, 11px + 3 * (100vw - 768px) / 1152, 14px);
}

.block {
  padding: var(--g-vertical) 0;
}
.block--wrapper {
  padding: 0 var(--container-padding);
  margin: auto;
  display: flex;
  flex-direction: column;
}

@media (min-width: 1024px) {
  .block_list .block--wrapper {
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
  }
  .block_list .block--wrapper .block--head {
    grid-area: heading;
    width: 80%;
  }
  .block_list .block--wrapper .block--selection {
    width: auto;
  }
  .block_list .block--wrapper .block--selection:has(.block--actions) {
    width: 100%;
  }
  .block_list .block--wrapper .block--sections {
    width: 100%;
  }
  .block_list .block--wrapper .block--elements {
    width: 100%;
  }
}
.block_list .block--wrapper--elements {
  margin-top: var(--g-section-inner);
}

.block.-centered .block--wrapper {
  align-items: center;
}
.block.-centered .block--head {
  text-align: center;
  align-items: center;
  max-width: 544px;
  margin: 0 auto;
}
.block.-centered .block--wrapper {
  justify-content: center;
}
.block.-centered .block--selection {
  width: 100%;
  justify-content: center;
}

.block--head {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  color: var(--c-text-default);
  font-size: var(--fs-body-sx);
}
@media (max-width: 768px) {
  .block--head {
    gap: 12px;
  }
}
.block--head_subtitle {
  width: fit-content;
  color: var(--c-accent);
}
.block--head_subtitle:empty {
  display: none;
}
.block--head_subtitle p:empty {
  display: none;
}
.block--head_title {
  font-size: clamp(28px, 28px + 16 * (100vw - 768px) / 1152, 44px);
}
.block--head_caption {
  opacity: 0.85;
  font-size: var(--fs-body-sm);
}
.block--head_caption:empty {
  display: none;
}
.block--head_caption p:empty {
  display: none;
}

.block--selection {
  display: flex;
  justify-content: space-between;
  gap: 8px;
}

.block--actions, .button_wrapper {
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-top: var(--g-section-inner);
}

.block--actions:empty {
  display: none;
}

.block--pagination {
  display: flex;
  justify-content: center;
  grid-column: span 2;
  gap: 8px;
}
@media (max-width: 768px) {
  .block--pagination {
    grid-column: span 1;
  }
}
.block--pagination .button {
  aspect-ratio: 1/1;
  padding: 10px;
  border: 1px solid var(--c-secondary);
  border-radius: var(--border-corner-button);
  width: auto;
  height: 40px;
  color: var(--c-secondary);
}
@media (min-width: 769px) {
  .block--pagination .button {
    height: 44px;
  }
}
.block--pagination .button.is_current {
  color: var(--c-white);
}
.block--pagination .button:has([class*=icon-]) {
  color: var(--c-white);
  border: none;
  background-color: var(--c-secondary);
}
.block--pagination .button:only-child {
  padding: 10px 24px;
  aspect-ratio: auto;
}

.block--sections {
  padding: var(--g-section-inner) 0 0;
  margin-top: var(--g-section-inner);
  border-top: 1px solid var(--c-secondary);
}
@media (max-width: 768px) {
  .block--sections {
    max-width: 100vw;
    overflow-x: auto;
    padding: var(--g-section-inner) var(--container-padding);
    margin: var(--g-section-inner) calc(var(--container-padding) * -1) 0;
  }
}
.block--sections a {
  border-radius: 100px;
  text-align: center;
  white-space: nowrap;
  padding: 6px 12px;
  font-size: var(--fs-body-sm);
  color: #FFFFFF;
  opacity: 0.64;
  background-color: transparent;
  z-index: 3;
  transition: var(--transition);
}
.block--sections a:hover, .block--sections a:focus, .block--sections a:active {
  opacity: 1;
}
.block--sections a.is_active {
  color: var(--c-text-default);
  opacity: 1;
}

.block--sections_selector {
  position: absolute;
  top: 2px;
  left: 0;
  background-color: #CAFA65;
  border-radius: 100px;
  height: calc(100% - 4px);
  z-index: 2;
  transition: var(--transition);
}

.block--link {
  margin-top: var(--g-section-inner-32);
  display: flex;
  justify-content: end;
  align-items: flex-end;
}

.block_banner {
  overflow: hidden;
  position: relative;
  display: flex;
}
.block_banner .block--wrapper {
  height: 100%;
  grid-column: content;
  position: relative;
  width: 100%;
}
.block_banner .block--head,
.block_banner .block--selection {
  z-index: 2;
  position: relative;
}
.block_banner .block--content {
  width: 100vw;
  margin: var(--g-section-inner-48) calc(var(--container-padding) * -1) 0;
  overflow: hidden;
  order: 1;
}
.block_banner .block--content img, .block_banner .block--content video {
  width: 100%;
  height: 100%;
  object-position: center;
  object-fit: cover;
  aspect-ratio: 1140/630;
}
.block_banner .block--head > * {
  z-index: 2;
  position: relative;
}
.block_banner .block--head .block--head_caption {
  max-width: 600px;
}

.block_banner-group .banner {
  background-color: var(--c-base-bgc);
}
.block_banner-group .slider_controls {
  display: flex;
  gap: 16px;
  order: 1;
  width: 100%;
  align-items: center;
  color: #fff;
  z-index: 10;
  justify-content: center;
  pointer-events: none;
}

.block_banner.-centered .block--actions {
  justify-content: center;
}

.block_image_text {
  padding: var(--g-section-inner) var(--container-padding);
  max-width: 100vw;
  --padding: clamp(16px, calc(16px + (64 - 16) * ((100vw - 768px) / (1920 - 768))), 64px);
  --padding-text: clamp(0px, calc(0px + (64 - 0) * ((100vw - 768px) / (1920 - 768))), 64px);
}
.block_image_text .block--wrapper {
  padding: 0;
  display: grid;
  grid-template-columns: 1fr;
  place-content: start;
  place-items: center;
  gap: 16px;
  grid-template-areas: "image" "content";
}
@media (min-width: 768px) {
  .block_image_text .block--wrapper {
    grid-template-areas: "content image";
    grid-template-columns: 1fr 1fr;
  }
}
.block_image_text .block--content {
  margin-top: 0;
  width: 100%;
  padding-right: var(--padding-text);
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 24px 16px;
}
.block_image_text .block--content:only-child {
  padding-right: 0;
  display: grid;
  grid-template-rows: auto 1fr;
  place-items: start;
  grid-column: span 2;
}
@media (min-width: 768px) {
  .block_image_text .block--content:only-child {
    grid-template-columns: 1fr 1fr;
  }
  .block_image_text .block--content:only-child > .block--head {
    grid-column: 1;
  }
  .block_image_text .block--content:only-child > .is_cascade {
    grid-column: 2;
    grid-row: span 2;
  }
  .block_image_text .block--content:only-child > .block--actions {
    grid-column: 1;
  }
}
.block_image_text .block--content:only-child .block--head,
.block_image_text .block--content:only-child .block--actions {
  padding: 0 var(--padding-text) 0 0;
}
.block_image_text .block--image ~ .block--content {
  grid-area: content;
}
.block_image_text .block--image {
  grid-area: image;
  min-height: 100%;
  max-width: 100%;
  width: 100%;
  display: flex;
}
.block_image_text .block--image img, .block_image_text .block--image video {
  object-fit: cover;
  height: auto;
  width: 100%;
  overflow: hidden;
  transform: translateZ(0);
}
@media (min-width: 992px) {
  .block_image_text .block--image img, .block_image_text .block--image video {
    position: sticky;
    top: calc(var(--headerHeight, 90px) + 40px);
  }
}
.block_image_text .block--head {
  align-items: flex-start;
}
.block_image_text .block--head h1:not([class]),
.block_image_text .block--head h2:not([class]),
.block_image_text .block--head h3:not([class]) {
  font-size: var(--fs-3xl);
}
.block_image_text .block--content > .is_cascade > *:first-child {
  margin-top: 0;
}
.block_image_text .block--content > .is_cascade > *:last-child {
  margin-bottom: 0;
}
@media (min-width: 768px) {
  .block_image_text.block_image_text-reversed .block--wrapper {
    grid-template-areas: "image content";
  }
}
.block_image_text.block_image_text-reversed .block--content {
  padding-left: var(--padding-text);
  padding-right: 0;
}
.block_image_text[class*=block_image_text-bg] .block--wrapper {
  padding: var(--padding);
  background-color: var(--bg-color);
  border-radius: var(--border-radius-2xl);
}
.block_image_text.block_image_text-ratio_square .block--image img, .block_image_text.block_image_text-ratio_square .block--image video {
  aspect-ratio: 1/1;
}
.block_image_text.block_image_text-ratio_portrait .block--image img, .block_image_text.block_image_text-ratio_portrait .block--image video {
  aspect-ratio: 3/4;
}
.block_image_text.block_image_text-ratio_landscape .block--image img, .block_image_text.block_image_text-ratio_landscape .block--image video {
  aspect-ratio: 16/9;
}
.block_image_text.block_image_text-ratio_fill .block--image {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
}
@media (max-width: 768px) {
  .block_image_text.block_image_text-ratio_fill .block--image {
    aspect-ratio: 1/1;
  }
}
.block_image_text.block_image_text-ratio_fill .block--image img, .block_image_text.block_image_text-ratio_fill .block--image video {
  position: absolute;
  top: 0;
  left: 0;
  aspect-ratio: unset;
  width: 100%;
  height: 100%;
}
.block_image_text.block_image_text-ratio_wide {
  padding: 0;
}
.block_image_text.block_image_text-ratio_wide[class*=block_image_text-bg] .block--wrapper {
  border-radius: 0;
  padding: 0;
}
@media (min-width: 768px) {
  .block_image_text.block_image_text-ratio_wide[class*=block_image_text-bg] .block--wrapper {
    padding-right: 0;
  }
}
.block_image_text.block_image_text-ratio_wide[class*=block_image_text-bg].block_image_text-reversed .block--wrapper {
  padding-left: 0;
}
@media (min-width: 768px) {
  .block_image_text.block_image_text-ratio_wide.block_image_text-reversed .block--content {
    padding-left: var(--padding-text);
    padding-right: var(--container-padding);
  }
}
.block_image_text.block_image_text-ratio_wide .block--content {
  padding: 0 var(--container-padding) 24px;
}
@media (min-width: 768px) {
  .block_image_text.block_image_text-ratio_wide .block--content {
    padding-left: var(--container-padding);
    padding-right: var(--padding-text);
    padding-top: var(--padding-text);
    padding-bottom: var(--padding-text);
  }
}
.block_image_text.block_image_text-ratio_wide .block--image {
  position: relative;
  width: 100%;
  height: 100%;
}
@media (max-width: 768px) {
  .block_image_text.block_image_text-ratio_wide .block--image {
    aspect-ratio: 1/1;
  }
}
.block_image_text.block_image_text-ratio_wide img, .block_image_text.block_image_text-ratio_wide video {
  border-radius: 0;
  position: absolute;
  top: 0;
  left: 0;
  aspect-ratio: unset;
  width: 100%;
  height: 100%;
}

.block_list .block--elements {
  display: flex;
  flex-direction: column;
  gap: var(--g-section-inner) var(--gap);
  margin-top: var(--g-section-inner);
}
@media (min-width: 768px) {
  .block_list .block--elements {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
  .block_list .block--elements .block--pagination {
    grid-column: span 2;
  }
}
.block_list .block--elements .card {
  width: 100%;
}

.block_list-slider {
  position: relative;
  overflow: hidden;
}
.block_list-slider .block--elements {
  display: flex;
  position: relative;
  padding-bottom: 80px;
}

.block_list-slider .slider_controls {
  padding: 0;
  position: absolute;
  width: fit-content;
  bottom: 0;
  left: 50%;
  translate: -50% 0;
  display: flex;
  gap: 8px;
  justify-content: space-between;
}

.card {
  flex-shrink: 0;
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  gap: 16px;
  overflow: hidden;
  border-radius: var(--border-corner-card);
  height: 100%;
}

.card--content {
  padding: var(--g-section-inner-24);
}

.card--title {
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  transition: var(--transition);
  font-weight: 700;
  font-size: var(--fs-heading-md);
  color: var(--c-text-default);
  margin-bottom: 8px;
}
.card--title p, .card--title h3 {
  color: var(--c-text-default);
}

.card--text {
  color: var(--c-text-secondary);
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.card[data-openpopup] {
  cursor: pointer;
}

.card--image {
  display: flex;
  align-items: flex-start;
  position: relative;
  z-index: 2;
  background: url("/assets/img/base_image.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  flex: 0 0 auto;
  aspect-ratio: 439/332;
}
.card--image img, .card--image video {
  transition: none;
  width: 100%;
  height: 100%;
  aspect-ratio: 439/332;
  object-fit: cover;
  object-position: center;
  user-drag: none;
}

.card--actions {
  display: flex;
}

/* Video widget */
.video_widget {
  position: fixed;
  bottom: var(--g-content, 40px);
  left: var(--g-content, 40px);
  justify-self: start;
  border: 3px solid #fff;
  transition: 0.18s ease-in-out;
  transform: scale(0.8);
  transform-origin: bottom left;
  max-width: 164px;
  aspect-ratio: 124/189;
  height: auto;
  overflow: hidden;
  cursor: pointer;
  z-index: 100;
  border-radius: 16px;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.04), 0px 8px 16px 0px rgba(0, 0, 0, 0.08);
  background: rgba(0, 0, 0, 0.3);
}
.video_widget:not(.is_active):hover {
  border-color: var(--c-surface-accent);
  transform: scale(1);
}
.video_widget.is_active {
  max-width: 312px;
  width: 312px;
  border-color: var(--c-surface-accent);
  transform: scale(1);
}

@media (max-width: 768px) {
  .video_widget {
    bottom: 12px;
    left: 12px;
  }
}
.video-controls--volume, .video_widget--close {
  border-radius: 50%;
  width: 42px;
  height: 42px;
  background-color: rgba(0, 0, 0, 0.4);
  color: #fff;
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  position: absolute;
  transform-origin: center;
  transform: scale(1);
  transition: var(--transition);
}

.video-controls--volume:hover, .video-controls--volume:active {
  transform: scale(1.1);
}

.video_widget--close {
  top: 6px;
  right: 4px;
  opacity: 0;
}
.video_widget--close:active, .video_widget--close:hover {
  transform: scale(1.1);
}

@media (max-width: 768px) {
  .video_widget--close {
    opacity: 1;
  }
}
.video_widget:not(.is_active):hover .video_widget--close, .video_widget.is_active .video_widget--close {
  opacity: 1;
}

.video-controls--volume {
  top: 52px;
  right: 4px;
  opacity: 0;
}
.video-controls--volume span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: var(--transition);
}
.video-controls--volume .icon-ic-volume {
  opacity: 0;
}
.video-controls--volume.is_not_muted .icon-ic-volume {
  opacity: 1;
}
.video-controls--volume.is_not_muted .icon-ic-volume-x {
  opacity: 0;
}

.video-controls video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

video[poster] {
  object-fit: cover;
}

.video-controls {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
  cursor: pointer;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.video_widget.is_active .video-controls {
  pointer-events: all;
}
.video_widget.is_active .video-controls--volume, .video_widget.is_active .video-controls--progress {
  opacity: 1;
}

.video-controls--progress {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 4px;
  --progress-bg:rgba(255, 255, 255, 0.6);
  --progress-line:var(--c-surface-accent);
  background-color: var(--progress-bg);
  transition: 0.18s ease-in;
  opacity: 0;
}
.video-controls--progress span {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  display: block;
  width: 0;
  background-color: var(--progress-line);
}

@media (max-width: 768px) {
  .video-controls--progress {
    opacity: 1;
  }
}
.video-controls--play {
  position: absolute;
  top: calc(50% - 32px);
  left: calc(50% - 32px);
  transition: var(--transition);
  border-radius: 50%;
  width: 64px;
  height: 64px;
  background-color: rgba(0, 0, 0, 0.2);
  color: #fff;
  opacity: 0;
  transform-origin: center;
  pointer-events: none;
}

.video_widget.is_active .video-controls--play.is_paused {
  opacity: 1;
}
.video_widget.is_active .video-controls--play.is_paused.is_ended {
  opacity: 1 !important;
}

.video-controls--play .icon-ic-play, .video-controls--play .icon-ic-pause {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 24px;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: var(--transition);
}
.video-controls--play.is_playing .icon-ic-play {
  opacity: 0;
}
.video-controls--play.is_playing .icon-ic-pause {
  opacity: 1;
}
.video-controls--play.is_paused .icon-ic-play {
  opacity: 1;
}
.video-controls--play.is_paused .icon-ic-pause {
  opacity: 0;
}

@keyframes pauseVideo {
  0% {
    opacity: 0;
    scale: 1;
  }
  30% {
    opacity: 100%;
    scale: 2;
  }
  100% {
    opacity: 0;
    scale: 1;
  }
}
.video-controls--button {
  position: absolute;
  bottom: 12px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 5;
  opacity: 0;
  transition: var(--transition);
}
.video-controls--button span {
  max-width: 220px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.video_widget.is_active .video-controls--button.is_shown {
  opacity: 1;
}

.video-controls--timer {
  font-size: 12px;
  color: #fff;
  width: 24px;
  position: absolute;
  top: 12px;
  left: 12px;
  z-index: 2;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
  opacity: 0;
  transition: var(--transition);
}

.video_widget.is_active .video-controls--timer {
  opacity: 1;
}

.content_404 .block--wrapper {
  padding: var(--g-section-inner) var(--container-padding);
  display: flex;
  flex-direction: column;
  align-items: center;
}
.content_404 .block--head {
  align-items: center;
}
.content_404 h1 {
  font-size: clamp(32px, 32px + 64 * (100vw - 1024px) / 896, 96px);
  text-align: center;
}

@media (min-width: 769px) {
  .content_blog .block--elements {
    grid-template-columns: repeat(3, 1fr);
  }
  .content_blog .block--elements .block--pagination {
    grid-column: span 3;
  }
}
.content_blog .card {
  gap: clamp(12px, 12px + 8 * (100vw - 768px) / 1152, 20px);
  border-radius: 0;
}
.content_blog .card--content {
  padding: 0;
}
.content_blog .card--image, .content_blog .card img {
  aspect-ratio: 352/198;
  border-radius: var(--border-corner-card);
}
.content_blog .card--title {
  font-size: var(--fs-body-sm);
}

.content_blog-hero .block--elements {
  overflow: hidden;
  position: relative;
  display: flex;
  margin-top: var(--g-section-inner);
}
.content_blog-hero .card {
  gap: var(--g-section-inner-48);
  border-radius: 0;
  display: flex;
  flex-direction: column;
}
@media (min-width: 769px) {
  .content_blog-hero .card {
    display: grid;
    grid-template-columns: 7fr 5fr;
  }
}
.content_blog-hero .card--image, .content_blog-hero .card img {
  border-radius: var(--border-corner-card);
  aspect-ratio: 640/360;
}
.content_blog-hero .card--content {
  padding: 0;
  display: flex;
  flex-direction: column;
  height: 100%;
}
.content_blog-hero .card--title {
  font-size: var(--fs-heading-lg);
  -webkit-line-clamp: 3;
}
.content_blog-hero .card--text {
  margin-top: 8px;
  font-size: var(--fs-body-sm);
}
.content_blog-hero .card--actions {
  margin-top: auto;
}
.content_blog-hero .card--actions .button {
  margin-top: 28px;
}
.content_blog-hero .slider_controls {
  position: absolute;
  right: 0;
  bottom: 0;
  display: flex;
  gap: 8px;
  z-index: 10;
  pointer-events: all;
}

.content_blog-hero {
  padding-bottom: 0;
}

.content_blog-hero ~ .content_blog {
  padding-top: 0;
}

.content_case .card {
  border: 1px solid var(--c-secondary);
}
.content_case .card--title {
  padding: var(--g-section-inner-32);
}
.content_case .card--image, .content_case .card img {
  aspect-ratio: 544/310;
}
.content_case .card--content {
  padding: 0;
}
.content_case .card--content_item {
  display: grid;
  grid-template-columns: 1fr 2fr;
  padding: var(--g-section-inner-24) var(--g-section-inner-32);
  border-top: 1px solid var(--c-secondary);
  font-size: var(--fs-body-sx);
}

.content_plans .block--elements {
  grid-template-columns: 1fr;
  gap: var(--g-section-inner-32);
  position: relative;
}
@media (min-width: 769px) {
  .content_plans .block--elements {
    grid-template-columns: repeat(3, 1fr);
  }
  .content_plans .block--elements .block--pagination {
    grid-column: span 3;
  }
}
.content_plans .block--elements:after {
  content: "";
  position: absolute;
  translate: -50% -50%;
  top: 50%;
  left: 50%;
  backdrop-filter: blur(228px);
  width: 80%;
  height: 100%;
  z-index: -1;
}
.content_plans .card {
  aspect-ratio: 328/298;
  backdrop-filter: blur(40px);
  border: 1px solid var(--c-secondary);
}
@media (min-width: 769px) {
  .content_plans .card {
    aspect-ratio: 352/364;
  }
}
.content_plans .card--content {
  padding: var(--g-section-inner-32);
  height: 100%;
  display: flex;
  flex-direction: column;
}
.content_plans .card--subtitle {
  font-size: var(--fs-body-sx);
  margin-bottom: 8px;
  font-weight: 600;
}
.content_plans .card--title {
  display: flex;
  align-items: flex-end;
  margin-bottom: var(--g-section-inner-24);
}
.content_plans .card--title .card--price {
  line-height: 1;
  font-size: 32px;
  margin-right: 4px;
}
.content_plans .card--title .card--period {
  font-size: var(--fs-heading-md);
  font-weight: 400;
}
.content_plans .card--text {
  -webkit-line-clamp: initial;
}
.content_plans .card--text ul {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.content_plans .card--text ul li {
  display: flex;
  gap: 12px;
}
.content_plans .card--actions {
  margin-top: auto;
}
.content_plans .card--actions .button {
  width: 100%;
  margin-top: 16px;
}
.content_plans .card.is_accent {
  border: none;
  color: var(--c-black);
  background: linear-gradient(180deg, #CBFF5C 26.97%, #8BBE1C 100%);
}
.content_plans .card.is_accent .card--title, .content_plans .card.is_accent .card--text {
  color: var(--c-black);
}
.content_plans .card.is_accent .button {
  --filled-bgc-base: #121112;
  --filled-c-base: #FFFFFF;
  --filled-bgc-hover: #4D4D4D;
  --filled-c-hover: #FFFFFF;
}

.content_plans ~ .banner__callback {
  padding-top: 0;
}

.content_widget .card {
  background-color: #1E1D1E;
  border: 1px solid var(--c-secondary);
}
.content_widget .card--image, .content_widget .card img {
  aspect-ratio: 544/306;
}
.content_widget .card--content {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: var(--g-section-inner-32);
}
.content_widget .card--actions {
  display: flex;
  gap: 8px;
  margin-top: auto;
}
.content_widget .card--actions .button {
  margin-top: var(--g-section-inner-24);
}

.page-payment main {
  display: flex;
  flex-direction: column;
  height: calc(100vh - var(--headerHeight));
}
.page-payment main .content_contacts {
  margin-top: auto;
}

.content_banner-callback .banner {
  display: flex;
  overflow: hidden;
  border: 1px solid var(--c-secondary);
  border-radius: var(--border-corner-card);
  position: relative;
}
@media (min-width: 999px) {
  .content_banner-callback .banner {
    display: grid;
    grid-template-columns: 5fr 7fr;
  }
}
@media (min-width: 1140px) {
  .content_banner-callback .banner {
    grid-template-columns: 6fr 6fr;
  }
}
@media (min-width: 1241px) {
  .content_banner-callback .banner {
    grid-template-columns: 7fr 5fr;
  }
  .content_banner-callback .banner:after {
    content: "";
    position: absolute;
    inset: 0;
    background: linear-gradient(var(--c-secondary), transparent 1px), linear-gradient(90deg, var(--c-secondary), transparent 1px);
    background-size: 42px 42px;
    background-position: center right;
    z-index: -1;
    mask-image: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgb(0, 0, 0) 70%);
  }
}
.content_banner-callback .banner .block--head,
.content_banner-callback .banner .block--actions {
  padding: 0;
}
.content_banner-callback .banner--content {
  padding: clamp(32px, 32px + 32 * (100vw - 768px) / 1152, 64px);
}
.content_banner-callback .banner--image {
  display: flex;
  height: 100%;
}
@media (max-width: 998px) {
  .content_banner-callback .banner--image {
    display: none;
  }
}

.content_advantages {
  padding: var(--g-section-inner) var(--container-padding);
}
.content_advantages .block--wrapper {
  display: grid;
  gap: 0 var(--g-section-inner-64);
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  grid-template-areas: "heading" "elements" "image";
  padding: 0;
}
@media (min-width: 993px) {
  .content_advantages .block--wrapper {
    grid-template-columns: 1fr 1fr;
    grid-template-areas: "heading image" "elements image";
    grid-template-rows: auto 1fr;
  }
}
@media (min-width: 993px) {
  .content_advantages.content_advantages-reversed .block--wrapper {
    grid-template-areas: "image heading" "image elements";
  }
}
.content_advantages .block--elements {
  display: flex;
  flex-direction: column;
  gap: var(--g-section-inner-24);
}
.content_advantages .block--image {
  grid-area: image;
  height: 100%;
  position: relative;
}
@media (max-width: 768px) {
  .content_advantages .block--image {
    display: none;
  }
}
.content_advantages .block--image .advantages--item_image {
  display: none;
}
@media (min-width: 768px) {
  .content_advantages .block--image .advantages--item_image {
    position: sticky;
    top: calc(var(--headerHeight) + 40px);
  }
}
.content_advantages .block--image .advantages--item_image.is_active {
  display: flex;
}

.advantages--item {
  position: relative;
  padding-left: 24px;
  opacity: 0.4;
}
@media (min-width: 769px) {
  .advantages--item {
    padding-left: 36px;
  }
}
.advantages--item:after {
  content: "";
  position: absolute;
  height: 100%;
  width: 2px;
  background-color: var(--c-accent);
  border-radius: 100px;
  left: 0;
  top: 0;
}
.advantages--item_wrapper {
  position: relative;
}
@media (min-width: 769px) {
  .advantages--item .advantages--item_image {
    display: none !important;
  }
}
.advantages--item.is_active .advantages--item_image {
  display: block;
}
.advantages--item.is_active .advantages--item_content {
  pointer-events: auto;
  height: auto;
  opacity: 1;
}
.advantages--item.is_active .icon {
  transform: rotate(180deg);
  transform-origin: center;
}
.advantages--item_image {
  display: none;
}
.advantages--item_image img, .advantages--item_image video {
  aspect-ratio: 1/1;
  object-fit: cover;
  height: auto;
  width: 100%;
  border-radius: var(--border-corner-small);
  overflow: hidden;
}
.advantages--item_image.is_active img, .advantages--item_image.is_active video {
  opacity: 1;
  display: flex;
}
.advantages--item_button {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  position: relative;
  padding: var(--g-section-inner-24) 0;
  gap: 16px;
  color: var(--c-text-default);
}
.advantages--item_button_text {
  margin: 0;
  transition: var(--transition);
  font-weight: 700;
  font-size: var(--fs-heading-md);
}
.advantages--item_button .icon {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  flex-grow: 0;
  height: 1.33em;
  width: 1.33em;
  font-size: var(--fs-heading-md);
  transform: rotate(0deg);
  transition: var(--transition);
}
.advantages--item_button .icon:before {
  content: "\e940";
}
.advantages--item_content {
  height: 0;
  opacity: 0;
  pointer-events: none;
}
.advantages--item_content > *:first-child {
  margin-top: 0;
}
.advantages--item_content > *:last-child {
  padding-bottom: 24px;
  margin-bottom: 0;
}
.advantages--item.is_active {
  opacity: 1;
}

.content_advantages-icon .block--elements {
  grid-template-columns: repeat(3, 1fr);
  gap: 32px;
}
@media (max-width: 768px) {
  .content_advantages-icon .block--elements {
    gap: 24px;
    display: flex;
    flex-direction: column;
  }
}
.content_advantages-icon .card {
  display: grid;
  grid-template-columns: 15% 85%;
  border-radius: 0;
}
@media (min-width: 769px) {
  .content_advantages-icon .card {
    display: flex;
    flex-direction: column;
  }
}
.content_advantages-icon .card--image {
  width: 100%;
  max-width: 48px;
  aspect-ratio: 1/1;
  object-fit: contain;
}
.content_advantages-icon .card--content {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.content_advantages-icon .card--title {
  font-weight: 700;
  font-size: var(--fs-body-md);
}
.content_advantages-icon .card--text {
  color: var(--c-text-secondary);
  font-size: var(--fs-body-sm);
}
@media (max-width: 768px) {
  .content_advantages-icon .card:not(:last-child) {
    padding-bottom: 24px;
    border-bottom: 1px solid var(--c-secondary);
  }
}
@media (min-width: 769px) {
  .content_advantages-icon .card:not(:nth-child(3n+3)) {
    border-right: 1px solid var(--c-secondary);
    padding-right: 32px;
  }
}

.content_contacts .block--elements {
  display: grid;
  grid-template-columns: 1fr;
  gap: 16px;
  margin-top: var(--g-section-inner);
}
@media (min-width: 769px) {
  .content_contacts .block--elements {
    grid-template-columns: repeat(2, 1fr);
    gap: var(--g-section-inner-32);
  }
}
.content_contacts .card {
  border: 1px solid var(--c-secondary);
  background: #1E1D1E;
  padding: var(--g-section-inner-32);
  height: min-content;
}
.content_contacts .card--content {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0;
}
.content_contacts .card--title {
  display: flex;
  gap: 12px;
  margin-bottom: 16px;
}
.content_contacts .card--title [class^=icon-] {
  line-height: 1.3;
}
.content_contacts .card--text {
  line-height: 1.4;
  -webkit-line-clamp: inherit;
}
.content_contacts .card--action {
  display: flex;
  gap: 8px;
  margin-top: auto;
}
.content_contacts .card--action .button {
  margin-top: 16px;
}
.content_contacts .card:only-child {
  text-align: center;
}
@media (min-width: 769px) {
  .content_contacts .card:only-child {
    max-width: 50%;
    margin: auto;
    grid-column: span 2;
  }
}
.content_contacts .card:only-child .card--title,
.content_contacts .card:only-child .card--action {
  justify-content: center;
}

.page--documentation {
  --gap-documentation: 56px;
}
@media (min-width: 999px) {
  .page--documentation {
    --gap-documentation: clamp(24px, calc(24px + (80 - 24) * ((100vw - 1240px) / (1920 - 1240))), 80px);
  }
  .page--documentation .page {
    display: grid;
    grid-template-columns: 300px auto;
    gap: var(--gap-documentation);
  }
}
@media (min-width: 999px) and (min-width: 1240px) {
  .page--documentation .page {
    grid-template-columns: 320px auto;
  }
}
@media (min-width: 999px) {
  .page--documentation main {
    padding: 0;
  }
}

.content_documentation {
  padding-top: var(--g-section-inner-32);
}
.content_documentation .block--wrapper {
  display: flex;
  flex-direction: column-reverse;
  gap: var(--gap-documentation);
}
@media (min-width: 999px) {
  .content_documentation .block--wrapper {
    padding: 0;
    display: grid;
    grid-template-columns: auto 300px;
  }
}
@media (min-width: 999px) and (min-width: 1240px) {
  .content_documentation .block--wrapper {
    grid-template-columns: auto 320px;
  }
}
@media (min-width: 999px) {
  .content_documentation .block--wrapper article {
    margin: auto;
    max-width: 32vw;
    width: 100%;
  }
}

.sidebar {
  height: 100%;
  border-bottom: 1px solid var(--c-secondary);
  margin: 0 calc(var(--container-padding) * -1);
  padding: 0 var(--container-padding) var(--g-section-inner-48);
}
@media (min-width: 999px) {
  .sidebar {
    padding: 0 var(--g-section-inner-24);
    border: none;
    margin: 0;
  }
}
.sidebar--wrapper {
  position: sticky;
  top: var(--g-section-inner-32);
}
.sidebar--title {
  display: flex;
  align-items: center;
  gap: 6px;
  font-size: var(--fs-body-sx);
  margin-bottom: 10px;
}
.sidebar--title [class^=icon-] {
  font-size: 1.2em;
}
.sidebar--content {
  font-size: 15px;
}
@media (max-width: 998px) {
  .sidebar .sidebar--content {
    position: fixed;
    opacity: 0;
    pointer-events: none;
  }
}

.sidebar .list,
.popup[data-popup=popup_contents] .list {
  list-style-type: none;
}
.sidebar .list__item,
.popup[data-popup=popup_contents] .list__item {
  padding: 4px;
  color: var(--c-text-secondary);
}
.sidebar .list__item a,
.popup[data-popup=popup_contents] .list__item a {
  padding: 4px;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.sidebar .list__item a:hover,
.popup[data-popup=popup_contents] .list__item a:hover {
  color: #fff;
}
.sidebar .list__item.is_active,
.popup[data-popup=popup_contents] .list__item.is_active {
  color: #fff;
  border-color: #fff;
}
.sidebar .list .list .list__item,
.popup[data-popup=popup_contents] .list .list .list__item {
  border-left: 1px solid var(--c-text-secondary);
  margin-left: 6px;
}