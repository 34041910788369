.block--actions, .button_wrapper
  display: flex
  gap: 8px
  align-items: center
  justify-content: flex-start
  flex-wrap: wrap
  margin-top: var(--g-section-inner)

.block--actions:empty
  display: none
