.card
  flex-shrink: 0
  flex-grow: 0
  display: flex
  flex-direction: column
  gap: 16px
  overflow: hidden
  border-radius: var(--border-corner-card)
  height: 100%

.card--content
  padding: var(--g-section-inner-24)
.card--title
  text-overflow: ellipsis
  overflow: hidden
  display: -webkit-box
  -webkit-line-clamp: 2
  -webkit-box-orient: vertical
  transition: var(--transition)
  font-weight: 700
  font-size: var(--fs-heading-md)
  color: var(--c-text-default)
  margin-bottom: 8px
  p, h3
    color: var(--c-text-default)

.card--text
  color: var(--c-text-secondary)
  text-overflow: ellipsis
  overflow: hidden
  display: -webkit-box
  -webkit-line-clamp: 3
  -webkit-box-orient: vertical

.card[data-openpopup]
  cursor: pointer

.card--image

  display: flex
  align-items: flex-start
  position: relative
  z-index: 2
  background: url('/assets/img/base_image.jpg')
  background-size: cover
  background-position: center
  background-repeat: no-repeat
  flex: 0 0 auto
  //border-radius: var(--border-corner-tiny)
  aspect-ratio: 439 / 332
  img, video
    transition: none
    //border-radius: var(--border-corner-tiny)
    width: 100%
    height: 100%
    aspect-ratio: 439 / 332
    object-fit: cover
    object-position: center
    user-drag: none

.card--actions
  display: flex
