*
  margin: 0
  padding: 0
  box-sizing: border-box

button
  border: none
  background: transparent
  color: inherit
  font: inherit
  cursor: pointer
  text-decoration: none

a
  color: inherit
  cursor: pointer
  text-decoration: none

html
  -webkit-tap-highlight-color: transparent
