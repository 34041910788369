img
  width: 100%
  height: 100%
  object-fit: cover
  object-position: center

a[href], .button
  cursor: pointer
  text-decoration-skip-ink: none
  transition: 0.18s ease-out

.disabled
  opacity: 0.3
  cursor: default
  pointer-events: none

.hidden
  display: none


.ux_col
  padding: 180px
  display: flex
  flex-direction: column
  gap: 24px


.ui_grid_buttons
  max-width: 1440px
  margin: 24px auto
  display: grid
  grid-template-columns: repeat(3, 1fr)
  place-items: center
  gap: 24px
  padding: 24px
  &_title
    grid-column: span 3
    margin: 48px 24px 24px
    color: var(--c-text-default)

//*
  outline: indianred 1px solid
  outline-offset: -1px

body
  max-width: 100vw
  overflow-x: hidden
  position: relative
  //&:after
    pointer-events: none
    content: ""
    width: calc(100% - var(--container-padding) * 2)
    height: 100vh
    position: fixed
    display: block
    top: 0
    left: var(--container-padding)
    right: var(--container-padding)
    z-index: 100
    opacity: 0.1
    background-image: repeating-linear-gradient(90deg, indianred, indianred var(--column), transparent var(--column), transparent calc( var(--column) + var(--gap) ) )


.page
  min-height: 100vh

main
  z-index: 1
  padding-top: 86px
  @media(max-width: 768px)
    padding-top: 64px

section[id]
  scroll-margin-top: calc(var(--headerHeight) + var(--g-section-inner-80))

@keyframes slidein
  0%
    opacity: 0
    max-height: 0
  30%
    opacity: 1
  100%
    max-height: 200vh
